@charset "utf-8";
@use '../common' as *;
@use '../mixins' as *;

.page{
    &__guide{
        padding: 0 50px 100px;
        &__title{
            position: relative;
            margin-bottom: 40px;
            text-align: center;
            &__en{
                display: inline-block;
                font-size: 140px;
                font-weight: 600;
                font-family: $fontB;
                line-height: 1;
                background: linear-gradient(90deg, rgba(235,87,85,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            &__jp{
                position: absolute;
                width: 100%;
                bottom: 0;
                font-size: 70px;
                font-weight: 700;
                line-height: 1;
                span{
                    display: block;
                    font-size: 18px;
                }
            }
            &.color2 &__en {
                background: linear-gradient(90deg, rgba(242,153,83,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            &.color3 &__en {
                background: linear-gradient(90deg, rgba(185,107,220,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            &.color4 &__en {
                background: linear-gradient(90deg, rgba(190,189,189,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        &__catch{
            margin-bottom: 50px;
            font-size: 30px;
            font-weight: 500;
            text-align: center;
        }
        &__tabs{
            display: flex;
            list-style: none;
            justify-content: space-between;
            align-items: end;
            width: 100%;
            max-width: 1900px;
            margin: 0 auto;
            padding: 0;
            li{
                width: 19.8%;
                font-size: 18px;
                text-align: center;
                border-top: 4px solid #fff;
                border-left: 4px solid #fff;
                border-right: 4px solid #fff;
                border-bottom: 1px solid rgba($color: #707070, $alpha: .1);
                background-color: #fff;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                box-shadow: 0px 0px 15px -5px rgba($color: #000000, $alpha: .3);
                @include transition-effect();
                a{
                    display: block;
                    height: 64px;
                    line-height: 64px;
                    color: #0D0C33;
                    text-decoration: none;
                    @include transition-effect();
                }
                &:hover,
                &.current{
                    border-bottom: 1px solid #fff;
                    background: linear-gradient(to right,#F8E4D8 0%, #D6EAF7 100%);
                    a{
                        height: 84px;
                        line-height: 84px;
                    }
                }
            }
        }

        &__inner{
            position: relative;
            max-width: 1900px;
            margin: 0 auto 70px;
            padding: 50px 50px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            background-color: #fff;
            section{
                margin-bottom: 100px;
            }
            &__catch{
                margin-bottom: 30px;
                padding: 16px 30px;
                border-radius: 10px;
                background: linear-gradient(90deg, rgba(13,12,51,0.5) 0%,rgba(44,156,224,0.5) 100%);
                font-size: 30px;
                font-weight: 700;
                color: #fff;
            }
            &__catch2{
                margin-bottom: 40px;
                font-size: 30px;
                font-weight: 700;
                text-align: center;
            }
            &__title{
                margin: 0 auto 1rem;
                max-width: 1200px;
                font-size: 24px;
                font-weight: 500;
                a{
                    color: #2D9CDB;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
            &__text{
                margin: 0 auto 100px;
                max-width: 1200px;
                font-size: 18px;
                a{
                    color: #2D9CDB;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
            &__nav{
                display: flex;
                justify-content: center;
                list-style: none;
                margin: 0 auto 40px;
                padding: 0;
                max-width: 1100px;
                li{
                    position: relative;
                    width: calc(100% / 3);
                    text-align: center;
                    font-size: 18px;
                    border-right: 1px solid #707070;
                    &:first-child{
                        border-left: 1px solid #707070;
                    }
                    a{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        padding: 20px 0;
                        color: #222;
                        text-decoration: none;
                        @include transition-effect();
                        &:hover{
                            background-color: rgba($color: #D8D8D8, $alpha: .3);
                        }
                    }
                    &::after{
                        position: absolute;
                        bottom: 5px;
                        left: 50%;
                        display: block;
                        content: "";
                        margin: 0 auto;
                        width: 5px;
                        height: 5px;
                        border-left: 1px solid #222;
                        border-bottom: 1px solid #222;
                        transform: translateX(-50%) rotateZ(-45deg);
                    }
                }
            }
            &__flow{
                margin: 0 auto 40px;
                max-width: 1200px;
                padding: 40px 50px 10px;
                background-color: rgba($color: #588FC7, $alpha: .1);
                &__title{
                    margin-bottom: 20px;
                    font-size: 28px;
                    font-weight: 700;
                    text-align: center;
                    color: #588FC7;
                }
                &__item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px;
                    border-radius: 999px;
                    background-color: #fff;
                    &__step{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100px;
                        height: 100px;
                        border-radius: 999px;
                        background-color: #588FC7;
                        font-family: $fontB;
                        font-weight: 700;
                        font-size: 60px;
                        line-height: 1;
                        color: #fff;
                        span{
                            font-size: 20px;
                        }
                    }
                    &__detail{
                        width: calc(100% - 130px);
                        &__title{
                            font-size: 22px;
                            font-weight: 700;
                            color: #588FC7;
                        }
                        &__text{
                            margin: 0;
                            font-size: 16px;
                        }
                    }
                }
                &__arrow{
                    margin: 20px auto;
                    text-align: center;
                }
            }
            &__flow2{
                margin: 0 auto 40px;
                max-width: 1200px;
                padding: 40px 50px;
                background: linear-gradient(180deg, rgba(88,143,199,.1) 0%, rgba(211,101,31,.1) 50%);
                &__title{
                    margin-bottom: 20px;
                    font-size: 28px;
                    font-weight: 700;
                    text-align: center;
                    color: #588FC7;
                }
                &__item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px;
                    border-radius: 999px;
                    background-color: #fff;
                    &__step{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100px;
                        height: 100px;
                        border-radius: 999px;
                        background-color: #588FC7;
                        font-family: $fontB;
                        font-weight: 700;
                        font-size: 60px;
                        line-height: 1;
                        color: #fff;
                        span{
                            font-size: 20px;
                        }
                    }
                    &__detail{
                        width: calc(100% - 130px);
                        &__title{
                            font-size: 22px;
                            font-weight: 700;
                            color: #588FC7;
                        }
                        &__text{
                            margin: 0;
                            font-size: 16px;
                        }
                    }
                    &.step4{
                        .page__guide__inner__flow2__item__detail__title{
                            color: #6A88AD;
                        }
                        .page__guide__inner__flow2__item__step{
                            background-color: #6A88AD;
                        }
                    }
                    &.step5{
                        .page__guide__inner__flow2__item__detail__title{
                            color: #858089;
                        }
                        .page__guide__inner__flow2__item__step{
                            background-color: #858089;
                        }
                    }
                    &.step6{
                        .page__guide__inner__flow2__item__detail__title{
                            color: #9E7868;
                        }
                        .page__guide__inner__flow2__item__step{
                            background-color: #9E7868;
                        }
                    }
                    &.step7{
                        .page__guide__inner__flow2__item__detail__title{
                            color: #AF7150;
                        }
                        .page__guide__inner__flow2__item__step{
                            background-color: #AF7150;
                        }
                    }
                    &.step8{
                        .page__guide__inner__flow2__item__detail__title{
                            color: #C56A32;
                        }
                        .page__guide__inner__flow2__item__step{
                            background-color: #C56A32;
                        }
                    }
                    &.step9{
                        .page__guide__inner__flow2__item__detail__title{
                            color: #D3651F;
                        }
                        .page__guide__inner__flow2__item__step{
                            background-color: #D3651F;
                        }
                    }
                    &.step10{
                        .page__guide__inner__flow2__item__detail__title{
                            color: #D3651F;
                        }
                        .page__guide__inner__flow2__item__step{
                            background-color: #D3651F;
                        }
                    }
                }
                &__arrow{
                    margin: 20px auto;
                    text-align: center;
                    &.step4{
                        svg{
                            g{
                                fill: #6A88AD;
                            }
                        }
                    }
                    &.step5{
                        svg{
                            g{
                                fill: #858089;
                            }
                        }
                    }
                    &.step6{
                        svg{
                            g{
                                fill: #9E7868;
                            }
                        }
                    }
                    &.step7{
                        svg{
                            g{
                                fill: #AF7150;
                            }
                        }
                    }
                    &.step8{
                        svg{
                            g{
                                fill: #C56A32;
                            }
                        }
                    }
                    &.step9{
                        svg{
                            g{
                            fill: #D3651F;
                            }
                        }
                    }
                }
            }
            &__faq{
                margin: 0 auto 40px;
                max-width: 1200px;
                &__item{
                    padding: 20px 0;
                    border-bottom: 1px dotted #A9A9A9;
                    &__q{
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        position: relative;
                        margin: 0;
                        font-size: 22px;
                        font-weight: 700;
                        span{
                            font-size: 40px;
                            font-weight: 700;
                            font-family: $fontB;
                            color: #588FC7;
                        }
                    }
                    &__a{
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        position: relative;
                        margin: 0;
                        font-size: 18px;
                        span{
                            font-size: 40px;
                            font-weight: 700;
                            font-family: $fontB;
                            color: #D3651F;
                        }
                    }
                }
            }
        }
        &__form{
            position: relative;
            max-width: 1900px;
            margin: 0 auto;
            padding: 50px 50px;
            border-radius: 20px;
            background-color: #EFEFF1;
            &__title{
                margin-bottom: 60px;
                padding: 10px 30px;
                border-radius: 10px;
                background-color: #0D0C33;
                font-size: 30px;
                font-weight: 700;
                color: #fff;
            }
            &__subTitle{
                max-width: 1200px;
                margin: 0 auto 20px;
                font-size: 24px;
                font-weight: 700;
                color: #1F1F43;
            }
            &__terms{
                overflow-y: scroll;
                max-width: 1200px;
                height: 430px;
                margin: 0 auto 20px;
                padding: 20px;
                border: 1px solid #707070;
                background-color: #fff;
                h2{
                    font-size: 16px;
                }
                p{
                    font-size: 16px;
                }
                ol{
                    li{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}



@media only screen and (max-width: 991px) {

    .page{
        &__guide{
            @include spacing_vw(padding, 0 30 60, 640);
            &__title{
                @include spacing_vw(margin, 0 0 40, 640);
                &__en{
                    @include fz_vw(110, 640);
                }
                &__jp{
                    @include fz_vw(60, 640);
                    span{
                        @include fz_vw(18, 640);
                    }
                }
            }
            &__catch{
                @include spacing_vw(margin, 0 0 50, 640);
                @include fz_vw(26, 640);
                text-align: left;
                br{
                    display: none;
                }
            }
            &__tabs{
                li{
                    @include fz_vw(16, 640);
                    border-top: 2px solid #fff;
                    border-left: 2px solid #fff;
                    border-right: 2px solid #fff;
                    a{
                        @include img_vw(100%, 60, 640);
                        @include line_height_vw(60, 640);
                    }
                    &.current{
                        a{
                            @include img_vw(100%, 70, 640);
                            @include line_height_vw(70, 640);
                            @include fz_vw(18, 640);
                        }
                    }
                    
                }
            }
            &__inner{
                @include spacing_vw(margin, 0 0 50, 640);
                @include spacing_vw(padding, 30 20, 640);
                section{
                    @include spacing_vw(margin, 0 0 50, 640);
                }
                &__catch{
                    @include spacing_vw(margin, 0 0 30, 640);
                    @include spacing_vw(padding, 10 15, 640);
                    @include fz_vw(30, 640);
                }
                &__catch2{
                    @include spacing_vw(margin, 0 0 20, 640);
                    @include fz_vw(24, 640);
                    text-align: left;
                }
                &__title{
                    @include spacing_vw(margin, 0 0 20, 640);
                    @include fz_vw(24, 640);
                }
                &__text{
                    @include spacing_vw(margin, 0 0 50, 640);
                    @include fz_vw(20, 640);
                }
                &__nav{
                    @include spacing_vw(margin, 0 0 30, 640);
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    border-left: 1px solid #707070;
                    li{
                        width: calc(100% / 3);
                        @include fz_vw(14, 640);
                        &:first-child{
                            border-left: none;
                        }
                        a{
                            @include spacing_vw(padding, 20 0, 640);
                        }
                        &::after{
                            @include img_vw(8, 8, 640);
                        }
                    }
                }
                &__flow{
                    @include spacing_vw(margin, 0 0 50, 640);
                    @include spacing_vw(padding, 30 20 5, 640);
                    &__title{
                        @include fz_vw(24, 640);
                    }
                    &__item{
                        align-items: center;
                        &__step{
                            @include img_vw(100, 100, 640);
                            @include fz_vw(60, 640);
                            span{
                                @include fz_vw(20, 640);
                            }
                        }
                        &__detail{
                            width: 75%;
                            &__title{
                                @include fz_vw(20, 640);
                            }
                            &__text{
                                @include fz_vw(16, 640);
                            }
                        }
                    }
                    &__arrow{
                        @include spacing_vw(margin, 10 0, 640);
                    }
                }
                &__flow2{
                    @include spacing_vw(margin, 0 0 50, 640);
                    @include spacing_vw(padding, 30 20, 640);
                    &__title{
                        @include fz_vw(24, 640);
                    }
                    &__item{
                        align-items: center;
                        &__step{
                            @include img_vw(100, 100, 640);
                            @include fz_vw(60, 640);
                            span{
                                @include fz_vw(20, 640);
                            }
                        }
                        &__detail{
                            width: 75%;
                            &__title{
                                @include fz_vw(20, 640);
                            }
                            &__text{
                                @include fz_vw(16, 640);
                            }
                        }
                    }
                    &__arrow{
                        @include spacing_vw(margin, 10 0, 640);
                    }
                }
                &__faq{
                    margin: 0;
                    &__item{
                        @include spacing_vw(padding, 15 0, 640);
                        &__q{
                            margin: 0;
                            @include spacing_vw(gap, 15, 640);
                            @include fz_vw(22, 640);
                            span{
                                @include fz_vw(40, 640);
                            }
                        }
                        &__a{
                            margin: 0;
                            @include spacing_vw(gap, 15, 640);
                            @include fz_vw(18, 640);
                            span{
                                @include fz_vw(40, 640);
                            }
                        }
                    }
                }
            }
            &__form{
                @include spacing_vw(padding, 30 20, 640);
                &__title{
                    @include spacing_vw(margin, 0 0 30, 640);
                    @include spacing_vw(padding, 10 30, 640);
                    @include fz_vw(30, 640);
                }
                &__subTitle{
                    @include spacing_vw(margin, 0 0 20, 640);
                    @include fz_vw(24, 640);
                }
                &__terms{
                    @include spacing_vw(margin, 0 0 20, 640);
                    @include spacing_vw(padding, 20 20, 640);
                    h2{
                        @include fz_vw(20, 640);
                    }
                    p{
                        @include fz_vw(16, 640);
                        strong{
                        @include fz_vw(20, 640);
                        }
                    }
                    ol{
                        li{
                            @include fz_vw(16, 640);
                        }
                    }
                }
            }
        }
    }
}