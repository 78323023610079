@charset "utf-8";
@use '../common' as *;
@use '../mixins' as *;

.page{
    &__contact{
        padding: 0 50px 100px;
        &__inner{
            max-width: 1900px;
            margin: 0 auto 0;
            padding: 50px 50px;
            border-radius: 20px;
            background-color: #fff;
            &__title{
                position: relative;
                margin-bottom: 40px;
                text-align: center;
                &__en{
                    display: inline-block;
                    font-size: 140px;
                    font-weight: 600;
                    font-family: $fontB;
                    line-height: 1;
                    background: linear-gradient(90deg, rgba(235,87,85,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                &__jp{
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    font-size: 70px;
                    font-weight: 700;
                    line-height: 1;
                    span{
                        display: block;
                        font-size: 18px;
                    }
                }
                &.color2 &__en {
                    background: linear-gradient(90deg, rgba(242,153,83,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                &.color3 &__en {
                    background: linear-gradient(90deg, rgba(185,107,220,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                &.color4 &__en {
                    background: linear-gradient(90deg, rgba(190,189,189,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            &__catch{
                margin-bottom: 50px;
                font-size: 22px;
                font-weight: 500;
                text-align: center;
                line-height: 1.8;
            }
            &__complete{
                font-size: 22px;
                text-align: center;
                color: #E85757;
            }
            &__form{
                max-width: 1100px;
                margin: 0 auto;
                &__note{
                    font-size: 15px;
                    span{
                        color: #E85757;
                    }
                }
                &__check{
                    font-size: 22px;
                    text-align: center;
                    color: #E85757;
                }
                &__dl{
                    display: flex;
                    width: 100%;
                    margin-bottom: 20px;
                    padding: 2px;
                    border: 1px solid #E8E9ED;
                    border-radius: 10px;
                    background-color: #fff;
                    &__dt{
                        display: flex;
                        align-items: center;
                        width: 350px;
                        padding: 30px 25px;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                        background-color: rgba($color: #F0F0F2, $alpha: .5);
                        font-size: 20px;
                        font-weight: 500;
                        span{
                            color: #ED5559;
                        }
                    }
                    &__dd{
                        width: calc(100% - 350px);
                        padding: 30px 25px;
                        input[type="text"],
                        input[type="tel"],
                        input[type="email"]{
                            width: 100%;
                            padding: 8px;
                            border: 1px solid #A9A9A9;
                            border-radius: 4px;
                            font-size: 20px;
                            &.text-s{
                                width: 100px;
                            }
                        }
                        &__notice{
                            margin-top: 10px;
                            margin-bottom: 0;
                            font-size: 18px;
                            font-weight: 400;
                            color: #E85757;
                        }
                        &__medium{
                            width: 100%;
                            height: 100px;
                            padding: 8px;
                            border: 1px solid #A9A9A9;
                            border-radius: 4px;
                            font-size: 20px;
                        }
                        &__large{
                            width: 100%;
                            height: 245px;
                            padding: 8px;
                            border: 1px solid #A9A9A9;
                            border-radius: 4px;
                            font-size: 20px;
                        }
                        .error{
                            font-size: 18px;
                            font-weight: 400;
                            color: #E85757;
                        }
                    }
                }
                &__buttons{
                    display: flex;
                    justify-content: center;
                    gap: 20px;
                }
                &__button{
                    display: block;
                    position: relative;
                    width: 380px;
                    height: 80px;
                    background-color: #707070;
                    clip-path: polygon(0% 0%, 92% 0, 100% 50%, 92% 100%, 0% 100%);
                    font-size: 20px;
                    line-height: 80px;
                    font-weight: 700;
                    text-align: center;
                    button{
                        display: block;
                        margin: 0;
                        padding: 0;
                        background: none;
                        border: none;
                        border-radius: 0;
                        outline: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        display: block;
                        width: 100%;
                        position: relative;
                        text-decoration: none;
                        color: #222;
                        @include transition-effect();
                        &::after {
                            content: '';
                            width: 10px;
                            height: 10px;
                            border: 0;
                            border-top: solid 1px #222;
                            border-right: solid 1px #222;
                            transform: rotate(45deg);
                            position: absolute;
                            top: 0;
                            right: 24px;
                            bottom: 0;
                            margin: auto;
                            @include transition-effect();
                        }
                        &:hover{
                            background-color: #707070;
                            color: #fff;
                        }
                    }
                    &::before{
                        content: '';
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        width: 378px;
                        height: 78px;
                        clip-path: polygon(0% 0%, 92% 0, 100% 50%, 92% 100%, 0% 100%);
                        background-color: #fff;
                    }
                }
                &__back{
                    display: block;
                    position: relative;
                    width: 280px;
                    height: 80px;
                    background-color: #707070;
                    clip-path: polygon(100% 0, 100% 100%, 12% 100%, 0 50%, 12% 0);
                    font-size: 20px;
                    line-height: 80px;
                    font-weight: 700;
                    text-align: center;
                    button{
                        display: block;
                        margin: 0;
                        padding: 0;
                        background: none;
                        border: none;
                        border-radius: 0;
                        outline: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        display: block;
                        width: 100%;
                        position: relative;
                        text-decoration: none;
                        color: #222;
                        @include transition-effect();
                        &::after {
                            content: '';
                            width: 10px;
                            height: 10px;
                            border: 0;
                            border-top: solid 1px #222;
                            border-right: solid 1px #222;
                            transform: rotate(-135deg);
                            position: absolute;
                            top: 0;
                            left: 24px;
                            bottom: 0;
                            margin: auto;
                            @include transition-effect();
                        }
                        &:hover{
                            background-color: #707070;
                            color: #fff;
                        }
                    }
                    &::before{
                        content: '';
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        width: 278px;
                        height: 78px;
                        clip-path: polygon(100% 0, 100% 100%, 12% 100%, 0 50%, 12% 0);
                        background-color: #fff;
                    }
                }
            }
        }
    }
}



@media only screen and (max-width: 991px) {

    .page{
        &__contact{
            @include spacing_vw(padding, 0 30 100, 640);
            background-size: 140% auto;
            background-position: center top;
            &__inner{
                margin: 0;
                @include spacing_vw(padding, 30 20, 640);
                &__title{
                    @include spacing_vw(margin, 0 0 40, 640);
                    &__en{
                        @include fz_vw(110, 640);
                    }
                    &__jp{
                        @include fz_vw(45, 640);
                        span{
                            @include fz_vw(18, 640);
                        }
                    }
                }
                &__catch{
                    br{
                        display: none;
                    }
                    @include fz_vw(22, 640);
                    text-align: left;
                }
                &__complete{
                    @include fz_vw(22, 640);
                }
                &__form{
                    &__note{
                        @include fz_vw(15, 640);
                    }
                    &__check{
                        @include fz_vw(18, 640);
                        text-align: left;
                    }
                    &__dl{
                        &__dt{
                            width: 30%;
                            @include spacing_vw(padding, 20 15, 640);
                            @include fz_vw(16, 640);
                        }
                        &__dd{
                            width: 70%;
                            @include spacing_vw(padding, 20 15, 640);
                            @include fz_vw(16, 640);
                            input[type="text"],
                            input[type="tel"],
                            input[type="email"]{
                                @include spacing_vw(padding, 8, 640);
                                @include fz_vw(16, 640);
                                &.text-s{
                                    width: 12vw;
                                }
                            }
                            &__medium{
                                @include spacing_vw(padding, 8, 640);
                                @include fz_vw(16, 640);
                            }
                            &__large{
                                @include spacing_vw(padding, 8, 640);
                                @include fz_vw(16, 640);
                            }
                            &__notice{
                                margin-top: 2vw;
                                @include fz_vw(16, 640);
                            }
                            .error{
                                @include fz_vw(16, 640);
                            }
                        }
                    }
                    &__button{
                        @include img_vw(320, 70, 640);
                        @include fz_vw(18, 640);
                        @include line_height_vw(70, 640);
                        button{
                            &::after {
                                right: 3vw;
                                @include img_vw(12, 12, 640);
                            }
                        }
                        &::before{
                            @include img_vw(317, 67, 640);
                        }
                    }
                    &__back{
                        @include img_vw(170, 70, 640);
                        @include fz_vw(18, 640);
                        @include line_height_vw(70, 640);
                        clip-path: polygon(100% 0, 100% 100%, 15% 100%, 0 50%, 15% 0);
                        button{
                            &::after {
                                left: 3vw;
                                @include img_vw(12, 12, 640);
                            }
                        }
                        &::before{
                            @include img_vw(167, 67, 640);
                        clip-path: polygon(100% 0, 100% 100%, 15% 100%, 0 50%, 15% 0);
                        }
                    }
                }
            }
        }
    }
}