@charset "utf-8";
@use '../common' as *;
@use '../mixins' as *;

.page{
    &__results{
        padding: 0 50px 100px;
        &__inner{
            max-width: 1900px;
            margin: 0 auto 0;
            padding: 50px 50px;
            border-radius: 20px;
            background-color: #fff;
            &__title{
                position: relative;
                margin-bottom: 40px;
                text-align: center;
                &__en{
                    display: inline-block;
                    font-size: 140px;
                    font-weight: 600;
                    font-family: $fontB;
                    line-height: 1;
                    background: linear-gradient(90deg, rgba(235,87,85,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                &__jp{
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    font-size: 70px;
                    font-weight: 700;
                    line-height: 1;
                    span{
                        display: block;
                        font-size: 18px;
                    }
                }
                &.color2 &__en {
                    background: linear-gradient(90deg, rgba(242,153,83,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                &.color3 &__en {
                    background: linear-gradient(90deg, rgba(185,107,220,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                &.color4 &__en {
                    background: linear-gradient(90deg, rgba(190,189,189,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            &__catch{
                display: flex;
                align-items: center;
                gap: 15px;
                padding: 16px 30px;
                border-radius: 10px;
                background: linear-gradient(90deg, rgba(13,12,51,0.5) 0%,rgba(44,156,224,0.5) 100%);
                font-size: 30px;
                font-weight: 700;
                color: #fff;
                span{
                    display: block;
                    min-width: 100px;
                    padding: 4px 10px;
                    border: 1px solid #fff;
                    background-color: #fff;
                    text-decoration: none;
                    font-size: 18px;
                    font-weight: 400;
                    text-align: center;
                    color: #333;
                    &.cf{
                        a{
                            background-color: #219053;
                        }
                    }
                    &.mv{
                        a{
                            background-color: #303065;
                        }
                    }
                    &.pv{
                        a{
                            background-color: #ff8b8b;
                        }
                    }
                    &.vtr{
                        a{
                            background-color: #632980;
                        }
                    }
                    &.web{
                        a{
                            background-color: #f2994a;
                        }
                    }
                    &.web-cm{
                        a{
                            background-color: #f2994a;
                        }
                    }
                    &.web-movie{
                        a{
                            background-color: #f2994a;
                        }
                    }
                    &.uncategorized,
                    &.other{
                        a{
                            background-color: #fff;
                            border: 1px solid #ccc;
                            color: #333;
                        }
                    }
                    &.still-picture{
                        a{
                            background-color: #f2994a;
                        }
                    }
                    &.drama{
                        a{
                            background-color: #2d9cdb;
                        }
                    }
                    &.variety{
                        a{
                            background-color: #C3B83B;
                        }
                    }
                    &.information{
                        a{
                            background-color: #bdbdbd;
                        }
                    }
                    &.information-program{
                        a{
                            background-color: #bdbdbd;
                        }
                    }
                    &.movie{
                        a{
                            background-color: #eb5757;
                        }
                    }
                    &.magazine{
                        a{
                            background-color: #f2994a;
                        }
                    }
                }
            }
            &__catch2{
                margin-bottom: 50px;
                font-size: 22px;
                font-weight: 500;
                text-align: center;
                line-height: 1.8;
            }
            &__content{
                font-size: 16px;
                font-weight: 400;
                .wp-block-image{
                    margin-bottom: 2rem;
                    figcaption{
                        font-size: 14px;
                        text-align: center;
                    }
                }
                p{
                    font-size: 18px;
                    line-height: 1.75;
                    font-weight: 400;
                    margin-bottom: 2rem;
                }
                h2{
                    font-size: 26px;
                    font-weight: 700;
                }
                h3{
                    font-size: 22px;
                    font-weight: 700;
                }
            }
            &__category{
                position: relative;
                margin-bottom: 60px;
                padding: 30px 0;
                border-radius: 10px;
                background-color: rgba($color: #F5F5F5, $alpha: .5);
                &__title{
                    position: absolute;
                    top: 0;
                    left: 50%;
                    margin: 0;
                    transform: translateX(-50%) translateY(-50%);
                    font-size: 20px;
                    font-weight: 700;
                }
                &__list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    list-style: none;
                    margin: 0 auto;
                    padding: 0 20px;
                    li{
                        width: calc(100% / 6);
                        text-align: center;
                        font-size: 18px;
                        border-left: 1px solid #E3E3E3;
                        &:nth-child(6n) {
                            border-right: 1px solid #E3E3E3;
                        }
                        a{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            padding: 5px 0;
                            color: #222;
                            text-decoration: none;
                            @include transition-effect();
                            &:hover{
                                background-color: rgba($color: #D8D8D8, $alpha: .3);
                            }
                        }
                    }
                }
            }
            &__list{
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                &__item{
                    width: calc((100% - 80px)/3);
                    &__image{
                        overflow: hidden;
                        position: relative;
                        margin-bottom: 15px;
                        border-radius: 10px;
                        aspect-ratio: 88 / 56;
                        &__annotation{
                            position: absolute;
                            z-index: 1;
                            font-size: 14px;
                            right: 10px;
                            bottom: 10px;
                            margin: 0;
                            color: #fff;
                        }
                        img{
                            @include transition-effect();
                            object-fit: cover;
                            height: 100%;
                        }
                        a{
                            display: block;
                            position: relative;
                            overflow: hidden;
                            aspect-ratio: 88 / 56;
                            &::before{
                                content: '';
                                display: block;
                                position: absolute;
                                opacity: 0;
                                z-index: 1;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: rgba($color: #0C0C34, $alpha: .5);
                                @include transition-effect();
                            }
                            img{
                                @include transition-effect();
                                height: 100%;
                            }
                            &:hover{
                                &::before{
                                    opacity: 1;
                                }
                                &::after{
                                    opacity: 1;
                                }
                                img{
                                    transform: scale(1.05);
                                }
                            }
                        }
                    }
                    &__place{
                        margin-bottom: 30px;
                        font-size: 17px;
                        font-weight: 500;
                        color: #808080;
                    }
                    &__category{
                        display: flex;
                        margin-bottom: 10px;
                        font-size: 18px;
                        a{
                            display: block;
                            min-width: 100px;
                            padding: 4px 10px;
                            background-color: #fff;
                            border: 1px solid #fff;
                            text-decoration: none;
                            text-align: center;
                            color: #fff;
                        }
                        &.cf{
                            a{
                                background-color: #219053;
                            }
                        }
                        &.mv{
                            a{
                                background-color: #303065;
                            }
                        }
                        &.pv{
                            a{
                                background-color: #ff8b8b;
                            }
                        }
                        &.vtr{
                            a{
                                background-color: #632980;
                            }
                        }
                        &.web{
                            a{
                                background-color: #f2994a;
                            }
                        }
                        &.web-cm{
                            a{
                                background-color: #f2994a;
                            }
                        }
                        &.web-movie{
                            a{
                                background-color: #f2994a;
                            }
                        }
                        &.uncategorized,
                        &.other{
                            a{
                                background-color: #fff;
                                border: 1px solid #ccc;
                                color: #333;
                            }
                        }
                        &.still-picture{
                            a{
                                background-color: #f2994a;
                            }
                        }
                        &.drama{
                            a{
                                background-color: #2d9cdb;
                            }
                        }
                        &.variety{
                            a{
                                background-color: #C3B83B;
                            }
                        }
                        &.information{
                            a{
                                background-color: #bdbdbd;
                            }
                        }
                        &.information-program{
                            a{
                                background-color: #bdbdbd;
                            }
                        }
                        &.movie{
                            a{
                                background-color: #eb5757;
                            }
                        }
                        &.magazine{
                            a{
                                background-color: #f2994a;
                            }
                        }
                    }
                    &__title{
                        margin: 0;
                        font-size: 18px;
                        a{
                            color: #222222;
                            text-decoration: none;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            &__detail{
                margin: 0 auto 18px;
                &__image{
                    overflow: hidden;
                    margin: 0 auto 0;
                    text-align: center;
                    img{
                        height: auto;
                        border-radius: 10px;
                    }
                }
                &__title{
                    margin-bottom: 20px;
                    font-size: 30px;
                    font-weight: 500;
                    text-align: center;
                }
                &__text{
                    font-size: 16px;
                    line-height: 1.75;
                }
            }
            &__thumb{
                display: flex;
                flex-wrap: wrap;
                gap: 18px;
                margin: 0 auto 80px;
                &__item{
                    width: calc((100% - 54px)/4);
                    &__image{
                        cursor: pointer;
                        position: relative;
                        overflow: hidden;
                        border-radius: 10px;
                        img{
                            @include transition-effect();
                        }
                        &::before{
                            content: '';
                            display: block;
                            position: absolute;
                            opacity: 0;
                            z-index: 1;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba($color: #0C0C34, $alpha: .5);
                            @include transition-effect();
                        }
                        &:hover{
                            &::before{
                                opacity: 1;
                            }
                            img{
                                transform: scale(1.05);
                            }
                        }
                    }
                    &.current{
                        .page__scene__inner__thumb__item__image{
                            &::before{
                                opacity: 1;
                            }
                            img{
                                transform: scale(1.05);
                            }
                        }
                    }
                    &__title{
                        font-size: 16px;
                        line-height: 1.75;
                        text-align: center;
                    }
                    &__hidden{
                        display: none;
                    }
                }
            }
        }
    }
}



@media only screen and (max-width: 991px) {

    .page{
        &__results{
            @include spacing_vw(padding, 0 30 100, 640);
            background-size: 140% auto;
            background-position: center top;
            &__inner{
                margin: 0;
                @include spacing_vw(padding, 30 20, 640);
                &__title{
                    @include spacing_vw(margin, 0 0 40, 640);
                    &__en{
                        @include fz_vw(110, 640);
                    }
                    &__jp{
                        @include fz_vw(45, 640);
                        span{
                            @include fz_vw(18, 640);
                        }
                    }
                }
                &__catch{
                    @include spacing_vw(padding, 10 15, 640);
                    @include fz_vw(30, 640);
                    span{
                        min-width: 12vw;
                        @include fz_vw(14, 640);
                        @include spacing_vw(padding, 5 8, 640);
                    }
                }
                &__catch2{
                    @include spacing_vw(margin, 0 0 40, 640);
                    @include fz_vw(22, 640);
                }
                &__content{
                    @include fz_vw(16, 640);
                    .wp-block-image{
                        figcaption{
                            @include fz_vw(14, 640);
                        }
                    }
                    p{
                        @include fz_vw(18, 640);
                    }
                    h2{
                        @include fz_vw(26, 640);
                    }
                    h3{
                        @include fz_vw(22, 640);
                    }
                }
                &__category{
                    @include spacing_vw(padding, 20 10 20, 640);
                    &__title{
                        position: static;
                        @include fz_vw(22, 640);
                        @include spacing_vw(margin, 0 0 20, 640);
                        transform: none;
                        text-align: center;
                    }
                    &__list{
                        flex-wrap: wrap;
                        li{
                            width: calc(100% / 3);
                            @include fz_vw(18, 640);
                            &:nth-child(3n) {
                                border-right: 1px solid #E3E3E3;
                            }
                            a{
                                @include spacing_vw(padding, 5 0, 640);
                            }
                        }
                    }
                }
                &__list{
                    justify-content: space-between;
                    @include spacing_vw(gap, 30 0, 640);
                    &__item{
                        width: 48%;
                        &__image{
                            @include spacing_vw(margin, 0 0 5, 640);
                            &__annotation{
                                @include fz_vw(16, 640);
                            }
                        }
                        &__place{
                            @include spacing_vw(margin, 0 0 20, 640);
                            @include fz_vw(17, 640);
                        }
                        &__category{
                            @include spacing_vw(margin, 0 0 5, 640);
                            @include fz_vw(14, 640);
                            a{
                                min-width: 12vw;
                                @include spacing_vw(padding, 5 8, 640);
                            }
                        }
                        &__title{
                            @include fz_vw(18, 640);
                        }
                    }
                }
                &__detail{
                    @include spacing_vw(margin, 0 auto 20, 640);
                    &__image{
                        @include spacing_vw(margin, 0 auto 20, 640);
                    }
                    &__title{
                        @include spacing_vw(margin, 0 auto 20, 640);
                        @include fz_vw(30, 640);
                    }
                    &__text{
                        @include spacing_vw(margin, 0 auto 20, 640);
                        @include fz_vw(16, 640);
                    }
                }
                &__thumb{
                    @include spacing_vw(margin, 0 auto 80, 640);
                    gap: 0 5%;
                    &__item{
                        width: 30%;
                        &.current{
                            .page__scene__inner__thumb__item__image{
                                &::before{
                                    opacity: 1;
                                }
                                img{
                                    transform: scale(1.05);
                                }
                            }
                        }
                        &__title{
                            @include fz_vw(18, 640);
                        }
                    }
                }
            }
        }
    }
}