/* tag color */
img {
  max-width: 100%;
}

body {
  background-color: rgba(242, 242, 242, 0.5);
  background-image: url(/assets/img/bg.png);
  background-position: center bottom;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  color: #222;
}
body.noScroll {
  overflow: hidden;
}

.menu {
  display: none;
  position: fixed;
  z-index: 101;
  cursor: pointer;
  top: 1.8vw;
  right: 1.8vw;
  width: 8vw;
  height: 8vw;
  background-image: url(/assets/img/menu.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

@media only screen and (max-width: 991px) {
  .menu {
    display: block;
  }
}
.navigation {
  display: block;
  visibility: hidden;
  opacity: 0;
  overflow-y: scroll;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 50px;
  padding: calc(0.15625 * 50 * 1vw);
  background-color: #fff;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.navigation__close {
  display: block;
  position: absolute;
  cursor: pointer;
  top: 1.8vw;
  right: 1.8vw;
  width: 8vw;
  height: 8vw;
  background-image: url(/assets/img/close.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.navigation__logo {
  margin: 0px 0px 28px;
  margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 28 * 1vw);
  font-size: 15px;
  font-size: calc(0.15625 * 15 * 1vw);
  text-align: center;
}
.navigation__logo span {
  display: block;
}
.navigation__logo a {
  text-decoration: none;
  color: #222;
}
.navigation__logo img {
  width: 293px;
  width: calc(0.15625 * 293 * 1vw);
  height: auto;
}
.navigation__list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.navigation__list__item {
  border-bottom: 1px solid #E3E3E3;
  font-size: 22px;
  font-size: calc(0.15625 * 22 * 1vw);
  font-weight: 500;
}
.navigation__list__item a {
  display: block;
  padding: 28px 0px;
  padding: calc(0.15625 * 28 * 1vw) calc(0.15625 * 0 * 1vw);
  color: #222;
  text-decoration: none;
}
.navigation__list__item:first-child {
  border-top: 1px solid #E3E3E3;
}
.navigation.active {
  visibility: visible;
  opacity: 1;
}

.header {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 100;
  top: 0;
  padding: 0 0 0 10px;
  background-color: #fff;
  font-family: "IBM Plex Sans JP", sans-serif;
  font-weight: 500;
}
.header__logo {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  line-height: 1;
}
.header__logo img {
  vertical-align: middle;
  transform: translateY(-3px);
}
.header__logo span {
  margin-left: 10px;
  vertical-align: middle;
}
.header__logo a {
  text-decoration: none;
  color: #222;
}
.header__nav {
  display: flex;
  align-items: center;
}
.header__nav__list {
  display: flex;
  gap: 50px;
  margin: 0 60px 0 0;
  list-style: none;
}
.header__nav__list__item {
  font-size: 16px;
  font-weight: 400;
}
.header__nav__list__item a {
  text-decoration: none;
  color: #222;
}
.header__nav__list__item a:hover {
  text-decoration: underline;
}
.header__nav__result {
  position: relative;
  width: 120px;
  height: 80px;
  background-color: #2E9DE0;
  line-height: 80px;
  text-align: center;
  text-shadow: 1px 1px 2px #2080D7;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.header__nav__result a {
  display: block;
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
}
.header__nav__result::before {
  opacity: 0.3;
  display: block;
  content: "";
  mix-blend-mode: overlay;
  position: absolute;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(0, 0, 0) 100%);
  width: 120px;
  height: 80px;
}
.header__nav__result:hover {
  opacity: 0.7;
}
.header__nav__photo {
  position: relative;
  width: 240px;
  height: 80px;
  background-color: #F19B4B;
  line-height: 80px;
  text-align: center;
  text-shadow: 1px 1px 2px #ED7E35;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.header__nav__photo a {
  display: block;
  position: relative;
  z-index: 1;
  font-size: 22px;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
}
.header__nav__photo::before {
  opacity: 0.3;
  display: block;
  content: "";
  mix-blend-mode: overlay;
  position: absolute;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(0, 0, 0) 100%);
  width: 240px;
  height: 80px;
}
.header__nav__photo:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 1599px) {
  .header__nav__list {
    gap: 0 20px;
    margin-right: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .header {
    position: relative;
    padding: 0;
  }
  .header__nav__list {
    display: none;
  }
  .header__nav__result {
    display: none;
    width: 130px;
    width: calc(0.15625 * 130 * 1vw);
    height: 80px;
    height: calc(0.15625 * 80 * 1vw);
    line-height: 80px;
    line-height: calc(0.15625 * 80 * 1vw);
  }
  .header__nav__result a {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .header__nav__result::before {
    width: 130px;
    width: calc(0.15625 * 130 * 1vw);
    height: 80px;
    height: calc(0.15625 * 80 * 1vw);
  }
  .header__nav__photo {
    display: none;
    width: 160px;
    width: calc(0.15625 * 160 * 1vw);
    height: 80px;
    height: calc(0.15625 * 80 * 1vw);
    line-height: 1.2;
  }
  .header__nav__photo a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    width: calc(0.15625 * 160 * 1vw);
    height: 80px;
    height: calc(0.15625 * 80 * 1vw);
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .header__nav__photo::before {
    width: 160px;
    width: calc(0.15625 * 160 * 1vw);
    height: 80px;
    height: calc(0.15625 * 80 * 1vw);
  }
  .header__logo {
    position: static;
    margin: 10px 0px 10px 10px;
    margin: calc(0.15625 * 10 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 10 * 1vw) calc(0.15625 * 10 * 1vw);
    font-size: 14px;
    font-size: calc(0.15625 * 14 * 1vw);
    text-align: center;
  }
  .header__logo img {
    width: 204px;
    width: calc(0.15625 * 204 * 1vw);
    height: auto;
    transform: none;
  }
  .header__logo span {
    display: block;
  }
}
.footer {
  position: relative;
  padding: 40px 0 10px;
  background-color: #fff;
}
.footer__logo {
  position: absolute;
  top: 20px;
  left: 10px;
  font-size: 14px;
  line-height: 1;
}
.footer__logo img {
  vertical-align: middle;
}
.footer__logo span {
  margin-left: 10px;
  vertical-align: middle;
}
.footer__logo a {
  text-decoration: none;
  color: #222;
}
.footer__nav {
  margin-bottom: 40px;
}
.footer__nav__list {
  display: flex;
  gap: 0 60px;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1;
}
.footer__nav__list__item {
  font-size: 16px;
  font-weight: 500;
}
.footer__nav__list__item a {
  text-decoration: none;
  color: #181B1E;
}
.footer__nav__list__item a:hover {
  text-decoration: underline;
}
.footer__copy {
  font-size: 14px;
  text-align: center;
}

@media only screen and (max-width: 1299px) {
  .footer {
    padding: 80px 0 10px;
  }
}
@media only screen and (max-width: 991px) {
  .footer {
    padding: 16px 30px 100px;
    padding: calc(0.15625 * 16 * 1vw) calc(0.15625 * 30 * 1vw) calc(0.15625 * 100 * 1vw);
  }
  .footer__logo {
    position: static;
    margin: 0px 0px 36px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 36 * 1vw);
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .footer__logo img {
    width: 224px;
    width: calc(0.15625 * 224 * 1vw);
    height: auto;
  }
  .footer__nav {
    margin: 0px 0px 36px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 36 * 1vw);
  }
  .footer__nav__list__item {
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .footer__copy {
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
}
.fixedNav {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 201;
  width: 100%;
  padding: 10px;
}
.fixedNav__result {
  position: relative;
  width: 30%;
  height: 80px;
  background-color: #2E9DE0;
  line-height: 80px;
  text-align: center;
  text-shadow: 1px 1px 2px #2080D7;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.fixedNav__result a {
  display: block;
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
}
.fixedNav__result::before {
  opacity: 0.3;
  display: block;
  content: "";
  mix-blend-mode: overlay;
  position: absolute;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(0, 0, 0) 100%);
  width: 100%;
  height: 80px;
}
.fixedNav__result:hover {
  opacity: 0.7;
}
.fixedNav__photo {
  position: relative;
  width: 70%;
  height: 80px;
  background-color: #F19B4B;
  line-height: 80px;
  text-align: center;
  text-shadow: 1px 1px 2px #ED7E35;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.fixedNav__photo a {
  display: block;
  position: relative;
  z-index: 1;
  font-size: 22px;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
}
.fixedNav__photo::before {
  opacity: 0.3;
  display: block;
  content: "";
  mix-blend-mode: overlay;
  position: absolute;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(0, 0, 0) 100%);
  width: 100%;
  height: 80px;
}
.fixedNav__photo:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 991px) {
  .fixedNav {
    display: flex;
    padding: 1.8vw;
  }
  .fixedNav__result {
    width: 30%px;
    width: calc(0.15625 * 30% * 1vw);
    height: 80px;
    height: calc(0.15625 * 80 * 1vw);
    line-height: 80px;
    line-height: calc(0.15625 * 80 * 1vw);
  }
  .fixedNav__result a {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .fixedNav__result::before {
    width: 100%;
    height: 80px;
    height: calc(0.15625 * 80 * 1vw);
  }
  .fixedNav__photo {
    width: 70%px;
    width: calc(0.15625 * 70% * 1vw);
    height: 80px;
    height: calc(0.15625 * 80 * 1vw);
    line-height: 1.2;
  }
  .fixedNav__photo a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    height: calc(0.15625 * 80 * 1vw);
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .fixedNav__photo::before {
    width: 100%;
    height: 80px;
    height: calc(0.15625 * 80 * 1vw);
  }
}
.title {
  position: relative;
  margin-bottom: 40px;
}
.title__en {
  display: inline-block;
  font-size: 100px;
  font-weight: 600;
  font-family: "Barlow", serif;
  line-height: 1;
  background: linear-gradient(90deg, rgba(235, 87, 85, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.title__jp {
  position: absolute;
  bottom: 0;
  font-size: 50px;
  font-weight: 700;
  line-height: 1;
}
.title.color2 .title__en {
  background: linear-gradient(90deg, rgba(242, 153, 83, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.title.color3 .title__en {
  background: linear-gradient(90deg, rgba(185, 107, 220, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.title.color4 .title__en {
  background: linear-gradient(90deg, rgba(190, 189, 189, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 991px) {
  .title {
    margin: 0px 0px 40px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 40 * 1vw);
  }
  .title__en {
    font-size: 90px;
    font-size: calc(0.15625 * 90 * 1vw);
  }
  .title__jp {
    font-size: 40px;
    font-size: calc(0.15625 * 40 * 1vw);
  }
}
.button {
  position: relative;
  width: 480px;
  height: 80px;
  margin: 0 auto;
  background-color: #707070;
  clip-path: polygon(0% 0%, 94% 0, 100% 50%, 94% 100%, 0% 100%);
  font-size: 20px;
  line-height: 80px;
  font-weight: 700;
  text-align: center;
  text-indent: -1em;
}
.button a {
  display: block;
  position: relative;
  text-decoration: none;
  color: #222;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.button a[target=_blank]::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 24px;
  background-image: url(/assets/img/icon/blank.svg);
  width: 18px;
  height: 18px;
  transform: translateY(-50%);
}
.button a:not([target=_blank])::after {
  content: "";
  width: 10px;
  height: 10px;
  border: 0;
  border-top: solid 1px #222;
  border-right: solid 1px #222;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  right: 24px;
  bottom: 0;
  margin: auto;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.button a:hover {
  background-color: #707070;
  color: #fff;
}
.button::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 478px;
  height: 78px;
  clip-path: polygon(0% 0%, 94% 0, 100% 50%, 94% 100%, 0% 100%);
  background-color: #fff;
}

@media only screen and (max-width: 991px) {
  .button {
    width: 520px;
    width: calc(0.15625 * 520 * 1vw);
    height: 90px;
    height: calc(0.15625 * 90 * 1vw);
    font-size: 22px;
    font-size: calc(0.15625 * 22 * 1vw);
    line-height: 90px;
    line-height: calc(0.15625 * 90 * 1vw);
  }
  .button a[target=_blank]::after {
    right: 4vw;
    background-size: 100% auto;
    width: 18px;
    width: calc(0.15625 * 18 * 1vw);
    height: 18px;
    height: calc(0.15625 * 18 * 1vw);
  }
  .button a:not([target=_blank])::after {
    right: 4vw;
    width: 12px;
    width: calc(0.15625 * 12 * 1vw);
    height: 12px;
    height: calc(0.15625 * 12 * 1vw);
  }
  .button::before {
    width: 517px;
    width: calc(0.15625 * 517 * 1vw);
    height: 87px;
    height: calc(0.15625 * 87 * 1vw);
  }
}
.topicPath {
  max-width: 1900px;
  margin: 0 auto;
  padding: 50px 0;
}
.topicPath__list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0;
  padding: 0;
  list-style: none;
}
.topicPath__list__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  font-size: 18px;
  color: #A0A0A0;
}
.topicPath__list__item:not(:last-of-type)::after {
  content: "";
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  margin-right: 0.5rem;
  border-top: 1px solid #222;
  border-right: 1px solid #222;
  transform: rotate(45deg);
}
.topicPath__list__item a {
  color: #000;
}
.topicPath__list__item a:hover {
  text-decoration: none;
}

@media only screen and (max-width: 991px) {
  .topicPath {
    padding: 30px 0px;
    padding: calc(0.15625 * 30 * 1vw) calc(0.15625 * 0 * 1vw);
  }
  .topicPath__list__item {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .topicPath__list__item:not(:last-of-type)::after {
    width: 8px;
    width: calc(0.15625 * 8 * 1vw);
    height: 8px;
    height: calc(0.15625 * 8 * 1vw);
  }
}
.pageTop {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  right: 20px;
  bottom: 20px;
  transform: translateY(50px);
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.pageTop a {
  display: block;
  width: 60px;
  height: 60px;
  background-image: url(/assets/img/icon/pagetop.png);
  background-size: 60px;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.pageTop a:hover {
  opacity: 0.7;
}
.pageTop.show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

@media only screen and (max-width: 991px) {
  .pageTop {
    bottom: 16vw;
    right: 1.8vw;
  }
  .pageTop a {
    width: 80px;
    width: calc(0.15625 * 80 * 1vw);
    height: 80px;
    height: calc(0.15625 * 80 * 1vw);
    background-size: 100% auto;
  }
}
.pagination {
  display: block;
}
.pagination ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  list-style: none;
  margin: 0 0 0;
  padding: 50px 0;
}
.pagination ul li {
  font-size: 22px;
  font-family: "Barlow", serif;
  font-weight: 500;
  text-align: center;
}
.pagination ul li a {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
  text-decoration: none;
  color: #222;
  transition: 0.3s all cubic-bezier(0.4, 0, 0.2, 1);
}
.pagination ul li a:hover {
  background-color: #1F60A7;
  color: #fff;
}
.pagination ul li a.next {
  font-size: 0;
}
.pagination ul li a.next::before {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid #1F60A7;
  border-right: 8px solid #1F60A7;
  border-left: 8px solid transparent;
  border-bottom: 8px solid transparent;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  transition: 0.3s all cubic-bezier(0.4, 0, 0.2, 1);
}
.pagination ul li a.next:hover {
  background: none;
}
.pagination ul li a.next:hover::before {
  transform: translateY(-50%) translateX(-30%) rotate(45deg);
}
.pagination ul li a.prev {
  font-size: 0;
}
.pagination ul li a.prev::before {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid #1F60A7;
  border-right: 8px solid #1F60A7;
  border-left: 8px solid transparent;
  border-bottom: 8px solid transparent;
  transform: translateY(-50%) translateX(-50%) rotate(-135deg);
  transition: 0.3s all cubic-bezier(0.4, 0, 0.2, 1);
}
.pagination ul li a.prev:hover {
  background: none;
}
.pagination ul li a.prev:hover::before {
  transform: translateY(-50%) translateX(-70%) rotate(-135deg);
}
.pagination ul li span {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
  background-color: #fff;
  text-decoration: none;
  color: #000;
}
.pagination ul li span.current {
  background-color: #1F60A7;
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .pagination ul {
    gap: 1vw;
    list-style: none;
    margin: 0 0 0;
    padding: 6vw 0;
  }
  .pagination ul li {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .pagination ul li a {
    width: 8vw;
    height: 8vw;
    line-height: 8vw;
  }
  .pagination ul li a.next::before {
    border-top: 1vw solid #1F60A7;
    border-right: 1vw solid #1F60A7;
    border-left: 1vw solid transparent;
    border-bottom: 1vw solid transparent;
  }
  .pagination ul li a.prev::before {
    border-top: 1vw solid #1F60A7;
    border-right: 1vw solid #1F60A7;
    border-left: 1vw solid transparent;
    border-bottom: 1vw solid transparent;
  }
  .pagination ul li span {
    width: 8vw;
    height: 8vw;
    line-height: 8vw;
  }
  .pagination ul li.current span {
    width: 8vw;
    height: 8vw;
    line-height: 8vw;
  }
}
.screen-reader-text {
  display: none;
}

.page__top {
  min-height: 2000px;
}
.page__top__keyvisual {
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 972px;
  height: calc(0.05 * 972 * 1vw);
}
.page__top__keyvisual__item {
  position: relative;
  z-index: 10;
  margin: 0px 0px 0px 200px;
  margin: calc(0.05 * 0 * 1vw) calc(0.05 * 0 * 1vw) calc(0.05 * 0 * 1vw) calc(0.05 * 200 * 1vw);
}
.page__top__keyvisual__item__subTitle {
  margin: 0;
  font-size: 46px;
  font-size: calc(0.05 * 46 * 1vw);
  font-family: "Geologica", serif;
  font-weight: 600;
  color: #fff;
  text-shadow: 0px 0px 20px rgb(0, 0, 0), 1px 1px 0 rgba(0, 0, 0, 0.5), -1px 1px 0 rgba(0, 0, 0, 0.5), -1px -1px 0 rgba(0, 0, 0, 0.5), 1px -1px 0 rgba(0, 0, 0, 0.5);
}
.page__top__keyvisual__item__title {
  font-size: 70px;
  font-size: calc(0.05 * 70 * 1vw);
  font-weight: 700;
  color: #fff;
  text-shadow: 0px 0px 20px rgb(0, 0, 0), 1px 1px 0 rgba(0, 0, 0, 0.5), -1px 1px 0 rgba(0, 0, 0, 0.5), -1px -1px 0 rgba(0, 0, 0, 0.5), 1px -1px 0 rgba(0, 0, 0, 0.5);
}
.page__top__keyvisual__item__en {
  font-size: 20px;
  font-size: calc(0.05 * 20 * 1vw);
  font-weight: 500;
  color: #fff;
  text-shadow: 0px 0px 20px rgb(0, 0, 0), 1px 1px 0 rgba(0, 0, 0, 0.5), -1px 1px 0 rgba(0, 0, 0, 0.5), -1px -1px 0 rgba(0, 0, 0, 0.5), 1px -1px 0 rgba(0, 0, 0, 0.5);
}
.page__top__keyvisual__nav {
  display: flex;
  gap: 30px;
  position: absolute;
  z-index: 1;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}
.page__top__keyvisual__nav__item {
  cursor: pointer;
  width: 14px;
  height: 14px;
  border: 1px solid #fff;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.2);
}
.page__top__keyvisual__nav__item:hover, .page__top__keyvisual__nav__item.current {
  background-color: #fff;
}
.page__top__keyvisual__bg.bg1 .page__top__keyvisual__bg1 {
  opacity: 1;
  transform: scale(1);
}
.page__top__keyvisual__bg.bg2 .page__top__keyvisual__bg2 {
  opacity: 1;
  transform: scale(1);
}
.page__top__keyvisual__bg.bg3 .page__top__keyvisual__bg3 {
  opacity: 1;
  transform: scale(1);
}
.page__top__keyvisual__bg.bg4 .page__top__keyvisual__bg4 {
  opacity: 1;
  transform: scale(1);
}
.page__top__keyvisual__bg1, .page__top__keyvisual__bg2, .page__top__keyvisual__bg3, .page__top__keyvisual__bg4 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transform: scale(1.1);
  transition: all 2.5s cubic-bezier(0.4, 0, 0.2, 1);
}
.page__top__keyvisual__bg1 {
  background-image: url(/assets/img/top/keyvisual.jpg);
}
.page__top__keyvisual__bg2 {
  background-image: url(/assets/img/top/keyvisual2.jpg);
}
.page__top__keyvisual__bg3 {
  background-image: url(/assets/img/top/keyvisual3.jpg);
}
.page__top__keyvisual__bg4 {
  background-image: url(/assets/img/top/keyvisual4.jpg);
}
.page__top__about {
  max-width: 2000px;
  margin: 0 auto;
  padding: 90px 50px;
}
.page__top__about__catch {
  margin-bottom: 70px;
  font-size: 30px;
  font-weight: 500;
}
.page__top__about__subTitle {
  margin-bottom: 35px;
  font-size: 30px;
  font-weight: 700;
}
.page__top__about__list__item {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 60px;
}
.page__top__about__list__item__image {
  position: absolute;
  right: 20px;
  width: 520px;
}
.page__top__about__list__item__image.even {
  left: 20px;
  right: auto;
}
.page__top__about__list__item__detail {
  width: 100%;
  min-height: 310px;
  padding: 30px 560px 30px 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
.page__top__about__list__item__detail__title {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 500;
}
.page__top__about__list__item__detail__title span {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  line-height: 50px;
  text-align: center;
  background-color: #808080;
  font-family: "Geologica", serif;
  color: #fff;
}
.page__top__about__list__item__detail__text {
  margin: 0;
  font-size: 26px;
  line-height: 1.75;
}
.page__top__about__list__item__detail.even {
  padding: 30px 30px 30px 560px;
}
.page__top__scene {
  max-width: 2000px;
  margin: 0 auto;
  padding: 90px 0;
}
.page__top__scene .title {
  padding: 0 50px;
}
.page__top__scene__list {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  padding: 0 50px 0 30%;
  background-image: url(/assets/img/top/scene-bg.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right 72vw top;
}
.page__top__scene__list.bg1 {
  background-image: url(/assets/img/top/scene-bg.png);
}
.page__top__scene__list.bg2 {
  background-image: url(/assets/img/top/scene-bg2.png);
}
.page__top__scene__list.bg3 {
  background-image: url(/assets/img/top/scene-bg3.png);
}
.page__top__scene__list.bg4 {
  background-image: url(/assets/img/top/scene-bg4.png);
}
.page__top__scene__list.bg5 {
  background-image: url(/assets/img/top/scene-bg5.png);
}
.page__top__scene__list.bg6 {
  background-image: url(/assets/img/top/scene-bg6.png);
}
@media only screen and (min-width: 2000px) {
  .page__top__scene__list {
    padding: 0 50px 0 28.8%;
    background-image: url(/assets/img/top/scene-2000-bg.png);
    background-position: left top;
    background-size: auto 100%;
  }
  .page__top__scene__list.bg1 {
    background-image: url(/assets/img/top/scene-2000-bg.png);
  }
  .page__top__scene__list.bg2 {
    background-image: url(/assets/img/top/scene-2000-bg2.png);
  }
  .page__top__scene__list.bg3 {
    background-image: url(/assets/img/top/scene-2000-bg3.png);
  }
  .page__top__scene__list.bg4 {
    background-image: url(/assets/img/top/scene-2000-bg4.png);
  }
  .page__top__scene__list.bg5 {
    background-image: url(/assets/img/top/scene-2000-bg5.png);
  }
  .page__top__scene__list.bg6 {
    background-image: url(/assets/img/top/scene-2000-bg6.png);
  }
}
.page__top__scene__list__item {
  position: relative;
  overflow: hidden;
  width: calc((100% - 80px) / 3);
  border-radius: 10px;
  background-color: #fff;
}
.page__top__scene__list__item > a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page__top__scene__list__item__image img {
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__top__scene__list__item__image a {
  display: block;
  position: relative;
  overflow: hidden;
  /*
  &::after{
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      opacity: 0;
      top: 50%;
      left: 50%;
      @include img_vw(79, 79, 2000);
      background-repeat: no-repeat;
      background-image: url(/assets/img/icon/arrow.svg);
      background-size: 100% auto;
      transform: translateX(-50%) translateY(-50%);
      @include transition-effect();
  }*/
}
.page__top__scene__list__item__image a::before {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 12, 52, 0.5);
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__top__scene__list__item__image a:hover::before {
  opacity: 1;
}
.page__top__scene__list__item__image a:hover::after {
  opacity: 1;
}
.page__top__scene__list__item__image a:hover img {
  transform: scale(1.05);
}
.page__top__scene__list__item__detail {
  padding: 20px 20px 80px;
}
.page__top__scene__list__item__detail__title {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 500;
}
.page__top__scene__list__item__detail__text {
  font-size: 18px;
  line-height: 2;
}
.page__top__scene__list__item__detail__link {
  position: absolute;
  z-index: 1;
  left: 20px;
  bottom: 20px;
  width: 180px;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}
.page__top__scene__list__item__detail__link a {
  display: block;
  position: relative;
  padding: 5px 0;
  border-bottom: 1px dotted #707070;
  text-decoration: none;
  color: #222;
}
.page__top__scene__list__item__detail__link a::after {
  content: "";
  width: 8px;
  height: 8px;
  border: 0;
  border-top: solid 1px #333;
  border-right: solid 1px #333;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  margin: auto;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__top__scene__list__item__detail__link a:hover::after {
  right: 0;
}
.page__top__scene__list__item:hover .page__top__scene__list__item__image a::before {
  opacity: 1;
}
.page__top__scene__list__item:hover .page__top__scene__list__item__image a::after {
  opacity: 1;
}
.page__top__scene__list__item:hover .page__top__scene__list__item__image a img {
  transform: scale(1.05);
}
.page__top__scene__list__item:hover .page__top__scene__list__item__detail__link a::after {
  right: 0;
}
.page__top__work {
  padding: 90px 0;
  background-color: #fff;
}
.page__top__work .title {
  max-width: 2000px;
  margin: 0 auto 40px;
  padding: 0 50px;
}
.page__top__work__title {
  max-width: 2000px;
  margin: 0 auto 20px;
  padding: 0 50px;
  font-size: 28px;
  font-weight: 700;
}
.page__top__work__list {
  margin-bottom: 40px;
}
.page__top__work__list .slick-prev {
  position: absolute;
  z-index: 1;
  appearance: none;
  top: 50%;
  left: 30px;
  width: 44px;
  height: 88px;
  margin: 0;
  padding: 0;
  background: none;
  background-image: url(/assets/img/icon/prev.svg);
  background-repeat: no-repeat;
  border: none;
  font-size: 0;
  transform: translateY(-50%);
}
.page__top__work__list .slick-next {
  position: absolute;
  z-index: 1;
  appearance: none;
  top: 50%;
  right: 30px;
  width: 44px;
  height: 88px;
  margin: 0;
  padding: 0;
  background: none;
  background-image: url(/assets/img/icon/prev.svg);
  background-repeat: no-repeat;
  border: none;
  font-size: 0;
  transform: translateY(-50%) scale(-1, 1);
}
.page__top__work__list__item {
  position: relative;
  overflow: hidden;
  width: 300px;
  min-height: 380px;
  margin: 0 10px 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.3);
}
.page__top__work__list__item__image {
  position: relative;
  aspect-ratio: 88/56;
}
.page__top__work__list__item__image__annotation {
  position: absolute;
  z-index: 1;
  font-size: 14px;
  right: 10px;
  bottom: 10px;
  margin: 0;
  color: #fff;
}
.page__top__work__list__item__image img {
  width: 100%;
  height: auto;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
  object-fit: cover;
  height: 100%;
}
.page__top__work__list__item__image a {
  display: block;
  position: relative;
  overflow: hidden;
  aspect-ratio: 88/56;
  /*
  &::after{
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      opacity: 0;
      top: 50%;
      left: 50%;
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-image: url(/assets/img/icon/arrow.svg);
      background-size: 100% auto;
      transform: translateX(-50%) translateY(-50%);
      @include transition-effect();
  }
  */
}
.page__top__work__list__item__image a::before {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 12, 52, 0.5);
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__top__work__list__item__image a:hover::before {
  opacity: 1;
}
.page__top__work__list__item__image a:hover::after {
  opacity: 1;
}
.page__top__work__list__item__image a:hover img {
  transform: scale(1.05);
}
.page__top__work__list__item__detail {
  padding: 10px 20px 20px;
}
.page__top__work__list__item__detail__text {
  font-size: 17px;
  color: #808080;
}
.page__top__work__list__item__detail__tag {
  margin-bottom: 5px;
}
.page__top__work__list__item__detail__tag a {
  display: inline-block;
  padding: 2px 10px;
  background-color: #fff;
  border: 1px solid #fff;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
}
.page__top__work__list__item__detail__tag.cf a {
  background-color: #219053;
}
.page__top__work__list__item__detail__tag.mv a {
  background-color: #303065;
}
.page__top__work__list__item__detail__tag.pv a {
  background-color: #ff8b8b;
}
.page__top__work__list__item__detail__tag.vtr a {
  background-color: #632980;
}
.page__top__work__list__item__detail__tag.web a {
  background-color: #f2994a;
}
.page__top__work__list__item__detail__tag.web-cm a {
  background-color: #f2994a;
}
.page__top__work__list__item__detail__tag.web-movie a {
  background-color: #f2994a;
}
.page__top__work__list__item__detail__tag.uncategorized a, .page__top__work__list__item__detail__tag.other a {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}
.page__top__work__list__item__detail__tag.still-picture a {
  background-color: #f2994a;
}
.page__top__work__list__item__detail__tag.drama a {
  background-color: #2d9cdb;
}
.page__top__work__list__item__detail__tag.variety a {
  background-color: #C3B83B;
}
.page__top__work__list__item__detail__tag.information a {
  background-color: #bdbdbd;
}
.page__top__work__list__item__detail__tag.information-program a {
  background-color: #bdbdbd;
}
.page__top__work__list__item__detail__tag.movie a {
  background-color: #eb5757;
}
.page__top__work__list__item__detail__tag.magazine a {
  background-color: #f2994a;
}
.page__top__work__list__item__detail__title {
  margin-bottom: 0;
  font-size: 18px;
}
.page__top__work__list__item__detail__title a {
  color: #222;
  text-decoration: none;
}
.page__top__work__list__item__detail__title a:hover {
  text-decoration: underline;
}
.page__top__work__list__item__detail__link {
  width: 180px;
  font-size: 18px;
  font-weight: 700;
}
.page__top__work__list__item__detail__link a {
  display: block;
  position: relative;
  padding: 5px 0;
  border-bottom: 1px dotted #707070;
  text-decoration: none;
  color: #222;
}
.page__top__work__list__item__detail__link a::after {
  content: "";
  width: 8px;
  height: 8px;
  border: 0;
  border-top: solid 1px #333;
  border-right: solid 1px #333;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  margin: auto;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__top__work__list__item__detail__link a:hover::after {
  right: 0;
}
.page__top__contact {
  max-width: 2000px;
  margin: 0 auto;
  padding: 90px 50px 120px;
}
.page__top__contact__button {
  position: relative;
  max-width: 1000px;
  height: 170px;
  margin: 0 auto;
  background-color: #F19B4B;
  background-image: url(/assets/img/icon/arrow.svg);
  background-size: 50px auto;
  background-position: right 48px center;
  background-repeat: no-repeat;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__top__contact__button::before {
  display: block;
  position: absolute;
  z-index: 1;
  opacity: 0.3;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 170px;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(0, 0, 0) 100%);
  mix-blend-mode: overlay;
}
.page__top__contact__button a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  height: 170px;
  font-size: 44px;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  text-shadow: 0px 0px 20px #ED7D35, 1px 1px 0 #ED7D35, -1px 1px 0 #ED7D35, -1px -1px 0 #ED7D35, 1px -1px 0 #ED7D35;
}
.page__top__contact__button a span {
  display: block;
  font-size: 26px;
  font-weight: 600;
}
.page__top__contact__button:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 991px) {
  .page__top__keyvisual {
    width: 100%;
    height: 950px;
    height: calc(0.15625 * 950 * 1vw);
  }
  .page__top__keyvisual__item {
    margin: 0px 0px 0px 30px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 30 * 1vw);
  }
  .page__top__keyvisual__item__subTitle {
    font-size: 36px;
    font-size: calc(0.15625 * 36 * 1vw);
  }
  .page__top__keyvisual__item__title {
    font-size: 56px;
    font-size: calc(0.15625 * 56 * 1vw);
  }
  .page__top__keyvisual__item__en {
    font-size: 22px;
    font-size: calc(0.15625 * 22 * 1vw);
  }
  .page__top__keyvisual__item__bg1, .page__top__keyvisual__item__bg2, .page__top__keyvisual__item__bg3, .page__top__keyvisual__item__bg4 {
    width: 100%;
    height: 950px;
    height: calc(0.15625 * 950 * 1vw);
  }
  .page__top__keyvisual__bg1 {
    background-image: url(/assets/img/top/keyvisual-sp.jpg);
  }
  .page__top__keyvisual__bg2 {
    background-image: url(/assets/img/top/keyvisual2-sp.jpg);
  }
  .page__top__keyvisual__bg3 {
    background-image: url(/assets/img/top/keyvisual3-sp.jpg);
  }
  .page__top__keyvisual__bg4 {
    background-image: url(/assets/img/top/keyvisual4-sp.jpg);
  }
  .page__top__keyvisual__nav {
    bottom: 10vw;
    gap: 0px 34px;
    gap: calc(0.15625 * 0 * 1vw) calc(0.15625 * 34 * 1vw);
  }
  .page__top__keyvisual__nav__item {
    width: 18px;
    width: calc(0.15625 * 18 * 1vw);
    height: 18px;
    height: calc(0.15625 * 18 * 1vw);
  }
  .page__top__about {
    padding: 60px 30px;
    padding: calc(0.15625 * 60 * 1vw) calc(0.15625 * 30 * 1vw);
  }
  .page__top__about__catch {
    margin: 0px 0px 40px 0px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 40 * 1vw) calc(0.15625 * 0 * 1vw);
    font-size: 28px;
    font-size: calc(0.15625 * 28 * 1vw);
  }
  .page__top__about__subTitle {
    margin: 0px 0px 60px 0px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 60 * 1vw) calc(0.15625 * 0 * 1vw);
    font-size: 32px;
    font-size: calc(0.15625 * 32 * 1vw);
  }
  .page__top__about__list__item {
    display: block;
    position: relative;
    margin: 20px 0px 50px;
    margin: calc(0.15625 * 20 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 50 * 1vw);
  }
  .page__top__about__list__item__detail {
    width: auto;
    padding: 330px 20px 20px;
    padding: calc(0.15625 * 330 * 1vw) calc(0.15625 * 20 * 1vw) calc(0.15625 * 20 * 1vw);
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.3);
  }
  .page__top__about__list__item__detail__title {
    position: relative;
    margin: 0px 0px 20px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 20 * 1vw);
    padding: 8px 0px 0px 65px;
    padding: calc(0.15625 * 8 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 65 * 1vw);
    font-size: 30px;
    font-size: calc(0.15625 * 30 * 1vw);
  }
  .page__top__about__list__item__detail__title span {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    width: calc(0.15625 * 50 * 1vw);
    height: 50px;
    height: calc(0.15625 * 50 * 1vw);
    margin: 0;
    line-height: 50px;
    line-height: calc(0.15625 * 50 * 1vw);
  }
  .page__top__about__list__item__detail__text {
    font-size: 26px;
    font-size: calc(0.15625 * 26 * 1vw);
  }
  .page__top__about__list__item__detail.even {
    padding: 330px 20px 20px;
    padding: calc(0.15625 * 330 * 1vw) calc(0.15625 * 20 * 1vw) calc(0.15625 * 20 * 1vw);
    border-radius: 10px;
  }
  .page__top__about__list__item__image {
    position: absolute;
    top: -3vw;
    right: auto;
    left: 50%;
    width: 500px;
    width: calc(0.15625 * 500 * 1vw);
    height: auto;
    transform: translateX(-50%);
  }
  .page__top__about__list__item__image img {
    width: 100%;
  }
  .page__top__about__list__item__image.even {
    left: 50%;
  }
  .page__top__scene {
    padding: 60px 30px;
    padding: calc(0.15625 * 60 * 1vw) calc(0.15625 * 30 * 1vw);
  }
  .page__top__scene .title {
    padding: 0;
  }
  .page__top__scene__list {
    gap: 20px;
    gap: calc(0.15625 * 20 * 1vw);
    padding: 0;
    background: none;
  }
  .page__top__scene__list.bg1, .page__top__scene__list.bg2, .page__top__scene__list.bg3, .page__top__scene__list.bg4, .page__top__scene__list.bg5, .page__top__scene__list.bg6 {
    background: none;
  }
  .page__top__scene__list__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }
  .page__top__scene__list__item__image {
    width: 50%;
  }
  .page__top__scene__list__item__image a::after {
    width: 50px;
    width: calc(0.15625 * 50 * 1vw);
    height: 50px;
    height: calc(0.15625 * 50 * 1vw);
  }
  .page__top__scene__list__item__title {
    width: 50%;
    margin: 0;
    padding: 20px;
    padding: calc(0.15625 * 20 * 1vw);
    font-size: 30px;
    font-size: calc(0.15625 * 30 * 1vw);
  }
  .page__top__scene__list__item__detail {
    padding: 20px;
  }
  .page__top__scene__list__item__detail__title {
    margin-bottom: 0;
    font-size: 30px;
    font-size: calc(0.15625 * 30 * 1vw);
  }
  .page__top__scene__list__item__detail__text {
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .page__top__scene__list__item__detail__link {
    position: static;
    width: 32vw;
    margin: 0 auto;
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .page__top__scene__list__item__detail__link a {
    padding: 5px;
    padding: calc(0.15625 * 5 * 1vw);
  }
  .page__top__scene__list__item__detail__link a::after {
    right: 2vw;
    width: 8px;
    width: calc(0.15625 * 8 * 1vw);
    height: 8px;
    height: calc(0.15625 * 8 * 1vw);
  }
  .page__top__scene__list__item__detail__link a:hover::after {
    right: 0;
  }
  .page__top__work {
    padding: 60px 0px;
    padding: calc(0.15625 * 60 * 1vw) calc(0.15625 * 0 * 1vw);
  }
  .page__top__work .title {
    padding: 0px 30px;
    padding: calc(0.15625 * 0 * 1vw) calc(0.15625 * 30 * 1vw);
  }
  .page__top__work__title {
    padding: 0px 30px;
    padding: calc(0.15625 * 0 * 1vw) calc(0.15625 * 30 * 1vw);
  }
  .page__top__work__list .slick-prev {
    width: 44px;
    width: calc(0.15625 * 44 * 1vw);
    height: 88px;
    height: calc(0.15625 * 88 * 1vw);
    background-size: 100% auto;
  }
  .page__top__work__list .slick-next {
    width: 44px;
    width: calc(0.15625 * 44 * 1vw);
    height: 88px;
    height: calc(0.15625 * 88 * 1vw);
    background-size: 100% auto;
  }
  .page__top__work__list__item {
    width: 60vw;
    min-height: 80vw;
  }
  .page__top__work__list__item__image__annotation {
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__top__work__list__item__image a::after {
    width: 50px;
    width: calc(0.15625 * 50 * 1vw);
    height: 50px;
    height: calc(0.15625 * 50 * 1vw);
  }
  .page__top__work__list__item__detail {
    padding: 15px 20px 50px;
    padding: calc(0.15625 * 15 * 1vw) calc(0.15625 * 20 * 1vw) calc(0.15625 * 50 * 1vw);
  }
  .page__top__work__list__item__detail__text {
    margin: 0px 0px 10px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 10 * 1vw);
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .page__top__work__list__item__detail__tag a {
    margin: 0;
    padding: 5px 10px;
    padding: calc(0.15625 * 5 * 1vw) calc(0.15625 * 10 * 1vw);
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__top__work__list__item__detail__title {
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .page__top__contact {
    padding: 60px 30px 110px;
    padding: calc(0.15625 * 60 * 1vw) calc(0.15625 * 30 * 1vw) calc(0.15625 * 110 * 1vw);
  }
  .page__top__contact__button {
    width: 100%;
    height: 170px;
    height: calc(0.15625 * 170 * 1vw);
    background-size: 8vw auto;
    background-position: right 2vw center;
    text-align: center;
  }
  .page__top__contact__button a {
    width: 100%;
    height: 170px;
    height: calc(0.15625 * 170 * 1vw);
    font-size: 36px;
    font-size: calc(0.15625 * 36 * 1vw);
    line-height: 1.1;
  }
  .page__top__contact__button a span {
    font-size: 24px;
    font-size: calc(0.15625 * 24 * 1vw);
    line-height: 2;
  }
  .page__top__contact__button::before {
    width: 100%;
    height: 170px;
    height: calc(0.15625 * 170 * 1vw);
  }
}
.page__scene {
  padding: 0 50px;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.page__scene.train {
  background-image: url(/assets/img/scene/train/bg.jpg);
}
.page__scene.station {
  background-image: url(/assets/img/scene/station/bg.jpg);
}
.page__scene.bus {
  background-image: url(/assets/img/scene/bus/bg.jpg);
}
.page__scene.shopping {
  background-image: url(/assets/img/scene/shopping/bg.jpg);
}
.page__scene.office {
  background-image: url(/assets/img/scene/office/bg.jpg);
}
.page__scene.hotel {
  background-image: url(/assets/img/scene/hotel/bg.jpg);
}
.page__scene__inner {
  max-width: 1900px;
  margin: 100px auto 0;
  padding: 50px 50px;
  border-radius: 20px;
  background-color: #fff;
}
.page__scene__inner__title {
  position: relative;
  margin-bottom: 40px;
  text-align: center;
}
.page__scene__inner__title__en {
  display: inline-block;
  font-size: 140px;
  font-weight: 600;
  font-family: "Barlow", serif;
  line-height: 1;
  background: linear-gradient(90deg, rgba(235, 87, 85, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__scene__inner__title__jp {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 70px;
  font-weight: 700;
  line-height: 1;
}
.page__scene__inner__title__jp span {
  display: block;
  font-size: 18px;
}
.page__scene__inner__title.color2 .page__scene__inner__title__en {
  background: linear-gradient(90deg, rgba(242, 153, 83, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__scene__inner__title.color3 .page__scene__inner__title__en {
  background: linear-gradient(90deg, rgba(185, 107, 220, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__scene__inner__title.color4 .page__scene__inner__title__en {
  background: linear-gradient(90deg, rgba(190, 189, 189, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__scene__inner__catch {
  padding: 16px 30px;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(13, 12, 51, 0.5) 0%, rgba(44, 156, 224, 0.5) 100%);
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
.page__scene__inner__catch2 {
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.page__scene__inner__note {
  font-size: 16px;
  text-align: right;
}
.page__scene__inner__subTitle {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 700;
}
.page__scene__inner__text {
  margin-bottom: 80px;
  font-size: 18px;
}
.page__scene__inner__nav {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0 0 70px;
  padding: 0;
}
.page__scene__inner__nav li {
  width: 174px;
  text-align: center;
  font-size: 18px;
  border-right: 1px solid #707070;
}
.page__scene__inner__nav li:first-child {
  border-left: 1px solid #707070;
}
.page__scene__inner__nav li a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px 0;
  color: #222;
  text-decoration: none;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__scene__inner__nav li a:hover {
  background-color: rgba(216, 216, 216, 0.3);
}
.page__scene__inner__nav li.current a {
  background-color: rgba(216, 216, 216, 0.3);
}
.page__scene__inner__list {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin: 0 auto;
}
.page__scene__inner__list__item {
  position: relative;
  width: calc((100% - 80px) / 3);
  padding-bottom: 80px;
}
.page__scene__inner__list__item__image {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 10px;
}
.page__scene__inner__list__item__image img {
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
  height: auto;
}
.page__scene__inner__list__item__image a {
  display: block;
  position: relative;
  overflow: hidden;
}
.page__scene__inner__list__item__image a::before {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 12, 52, 0.5);
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__scene__inner__list__item__image a:hover::before {
  opacity: 1;
}
.page__scene__inner__list__item__image a:hover img {
  transform: scale(1.05);
}
.page__scene__inner__list__item__title {
  font-size: 22px;
  font-weight: 700;
}
.page__scene__inner__list__item__title a {
  color: #222;
  text-decoration: none;
}
.page__scene__inner__list__item__title a:hover {
  text-decoration: underline;
}
.page__scene__inner__list__item__subTitle {
  font-size: 22px;
  font-weight: 400;
  text-align: center;
}
.page__scene__inner__list__item__subTitle a {
  color: #222;
  text-decoration: none;
}
.page__scene__inner__list__item__subTitle a:hover {
  text-decoration: underline;
}
.page__scene__inner__list__item__text {
  font-size: 18px;
  font-weight: 400;
}
.page__scene__inner__list__item__link {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 120px;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}
.page__scene__inner__list__item__link a {
  display: block;
  position: relative;
  padding: 5px 0;
  border-bottom: 1px dotted #707070;
  text-decoration: none;
  color: #222;
}
.page__scene__inner__list__item__link a::after {
  content: "";
  width: 8px;
  height: 8px;
  border: 0;
  border-top: solid 1px #333;
  border-right: solid 1px #333;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  margin: auto;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__scene__inner__list__item__link a:hover::after {
  right: 0;
}
.page__scene__inner__detail {
  margin: 0 auto 18px;
}
.page__scene__inner__detail__image {
  overflow: hidden;
  border-radius: 10px;
  margin: 0 auto 0;
}
.page__scene__inner__detail__title {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}
.page__scene__inner__detail__text {
  font-size: 16px;
  line-height: 1.75;
}
.page__scene__inner__thumb {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  margin: 0 auto;
}
.page__scene__inner__thumb__item {
  width: calc((100% - 54px) / 4);
}
.page__scene__inner__thumb__item__image {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.page__scene__inner__thumb__item__image img {
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__scene__inner__thumb__item__image::before {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 12, 52, 0.5);
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__scene__inner__thumb__item__image:hover::before {
  opacity: 1;
}
.page__scene__inner__thumb__item__image:hover img {
  transform: scale(1.05);
}
.page__scene__inner__thumb__item.current .page__scene__inner__thumb__item__image::before {
  opacity: 1;
}
.page__scene__inner__thumb__item.current .page__scene__inner__thumb__item__image img {
  transform: scale(1.05);
}
.page__scene__inner__thumb__item__title {
  font-size: 16px;
  line-height: 1.75;
  text-align: center;
}
.page__scene__inner__thumb__item__hidden {
  display: none;
}
.page__scene__inner__notice {
  max-width: 950px;
  margin: 0 auto 60px;
}
.page__scene__inner__notice__title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
}
.page__scene__inner__notice__title span {
  background: linear-gradient(to right, #F8E4D8 0%, #D6EAF7 100%);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 100% 50%;
}
.page__scene__inner__notice ul {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}
.page__scene__inner__notice ul li {
  position: relative;
  padding-left: 1em;
  line-height: 1.75;
}
.page__scene__inner__notice ul li::before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: #707070;
  transform: translateY(-50%);
}
.page__scene__inner__notice p {
  line-height: 1.75;
}
.page__scene__inner__map iframe {
  width: 100%;
  height: 700px;
  border-radius: 10px;
}

@media only screen and (max-width: 991px) {
  .page__scene {
    padding: 0px 30px;
    padding: calc(0.15625 * 0 * 1vw) calc(0.15625 * 30 * 1vw);
    background-size: 140% auto;
    background-position: center top;
  }
  .page__scene__inner {
    margin: 50px 0px 0px;
    margin: calc(0.15625 * 50 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw);
    padding: 30px 20px;
    padding: calc(0.15625 * 30 * 1vw) calc(0.15625 * 20 * 1vw);
  }
  .page__scene__inner__title {
    margin: 0px 0px 40px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 40 * 1vw);
  }
  .page__scene__inner__title__en {
    font-size: 80px;
    font-size: calc(0.15625 * 80 * 1vw);
  }
  .page__scene__inner__title__jp {
    font-size: 60px;
    font-size: calc(0.15625 * 60 * 1vw);
  }
  .page__scene__inner__title__jp span {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__scene__inner__catch {
    padding: 10px 15px;
    padding: calc(0.15625 * 10 * 1vw) calc(0.15625 * 15 * 1vw);
    font-size: 30px;
    font-size: calc(0.15625 * 30 * 1vw);
  }
  .page__scene__inner__catch2 {
    margin: 0px 0px 20px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 20 * 1vw);
    font-size: 24px;
    font-size: calc(0.15625 * 24 * 1vw);
    text-align: left;
  }
  .page__scene__inner__note {
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__scene__inner__subTitle {
    margin: 0px 0px 10px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 10 * 1vw);
    font-size: 30px;
    font-size: calc(0.15625 * 30 * 1vw);
  }
  .page__scene__inner__text {
    margin: 0px 0px 80px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 80 * 1vw);
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__scene__inner__nav {
    margin: 0px 0px 30px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 30 * 1vw);
    flex-wrap: wrap;
    justify-content: flex-start;
    border-left: 1px solid #707070;
  }
  .page__scene__inner__nav li {
    width: 33.3333333333%;
    font-size: 14px;
    font-size: calc(0.15625 * 14 * 1vw);
  }
  .page__scene__inner__nav li:first-child {
    border-left: none;
  }
  .page__scene__inner__nav li a {
    padding: 5px 0px;
    padding: calc(0.15625 * 5 * 1vw) calc(0.15625 * 0 * 1vw);
  }
  .page__scene__inner__list {
    justify-content: space-between;
    gap: 30px 0px;
    gap: calc(0.15625 * 30 * 1vw) calc(0.15625 * 0 * 1vw);
  }
  .page__scene__inner__list__item {
    width: 48%;
    padding-bottom: 0;
  }
  .page__scene__inner__list__item__title {
    font-size: 22px;
    font-size: calc(0.15625 * 22 * 1vw);
  }
  .page__scene__inner__list__item__subTitle {
    font-size: 22px;
    font-size: calc(0.15625 * 22 * 1vw);
  }
  .page__scene__inner__list__item__text {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__scene__inner__list__item__link {
    position: static;
    width: 22vw;
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__scene__inner__list__item__link a {
    padding: 5px;
    padding: calc(0.15625 * 5 * 1vw);
  }
  .page__scene__inner__list__item__link a::after {
    right: 2vw;
    width: 8px;
    width: calc(0.15625 * 8 * 1vw);
    height: 8px;
    height: calc(0.15625 * 8 * 1vw);
  }
  .page__scene__inner__list__item__link a:hover::after {
    right: 0;
  }
  .page__scene__inner__detail {
    margin: 0px auto 20px;
    margin: calc(0.15625 * 0 * 1vw) auto calc(0.15625 * 20 * 1vw);
  }
  .page__scene__inner__detail__image {
    margin: 0px auto 20px;
    margin: calc(0.15625 * 0 * 1vw) auto calc(0.15625 * 20 * 1vw);
  }
  .page__scene__inner__detail__title {
    margin: 0px auto 20px;
    margin: calc(0.15625 * 0 * 1vw) auto calc(0.15625 * 20 * 1vw);
    font-size: 30px;
    font-size: calc(0.15625 * 30 * 1vw);
  }
  .page__scene__inner__detail__text {
    margin: 0px auto 20px;
    margin: calc(0.15625 * 0 * 1vw) auto calc(0.15625 * 20 * 1vw);
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__scene__inner__thumb {
    margin: 0px auto;
    margin: calc(0.15625 * 0 * 1vw) auto;
    gap: 0 5%;
  }
  .page__scene__inner__thumb__item {
    width: 30%;
  }
  .page__scene__inner__thumb__item.current .page__scene__inner__thumb__item__image::before {
    opacity: 1;
  }
  .page__scene__inner__thumb__item.current .page__scene__inner__thumb__item__image img {
    transform: scale(1.05);
  }
  .page__scene__inner__thumb__item__title {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__scene__inner__notice {
    max-width: 950px;
    margin: 0px auto 60px;
    margin: calc(0.15625 * 0 * 1vw) auto calc(0.15625 * 60 * 1vw);
  }
  .page__scene__inner__notice__title {
    margin: 0px 0px 20px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 20 * 1vw);
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .page__scene__inner__notice ul {
    margin: 0px 0px 20px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 20 * 1vw);
  }
  .page__scene__inner__notice ul li {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__scene__inner__notice ul li::before {
    width: 10px;
    width: calc(0.15625 * 10 * 1vw);
    height: 10px;
    height: calc(0.15625 * 10 * 1vw);
  }
  .page__scene__inner__notice p {
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__scene__inner__map iframe {
    width: 100%;
    height: 620px;
    height: calc(0.15625 * 620 * 1vw);
  }
}
.page__guide {
  padding: 0 50px 100px;
}
.page__guide__title {
  position: relative;
  margin-bottom: 40px;
  text-align: center;
}
.page__guide__title__en {
  display: inline-block;
  font-size: 140px;
  font-weight: 600;
  font-family: "Barlow", serif;
  line-height: 1;
  background: linear-gradient(90deg, rgba(235, 87, 85, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__guide__title__jp {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 70px;
  font-weight: 700;
  line-height: 1;
}
.page__guide__title__jp span {
  display: block;
  font-size: 18px;
}
.page__guide__title.color2 .page__guide__title__en {
  background: linear-gradient(90deg, rgba(242, 153, 83, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__guide__title.color3 .page__guide__title__en {
  background: linear-gradient(90deg, rgba(185, 107, 220, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__guide__title.color4 .page__guide__title__en {
  background: linear-gradient(90deg, rgba(190, 189, 189, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__guide__catch {
  margin-bottom: 50px;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}
.page__guide__tabs {
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  padding: 0;
}
.page__guide__tabs li {
  width: 19.8%;
  font-size: 18px;
  text-align: center;
  border-top: 4px solid #fff;
  border-left: 4px solid #fff;
  border-right: 4px solid #fff;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.3);
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__guide__tabs li a {
  display: block;
  height: 64px;
  line-height: 64px;
  color: #0D0C33;
  text-decoration: none;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__guide__tabs li:hover, .page__guide__tabs li.current {
  border-bottom: 1px solid #fff;
  background: linear-gradient(to right, #F8E4D8 0%, #D6EAF7 100%);
}
.page__guide__tabs li:hover a, .page__guide__tabs li.current a {
  height: 84px;
  line-height: 84px;
}
.page__guide__inner {
  position: relative;
  max-width: 1900px;
  margin: 0 auto 70px;
  padding: 50px 50px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #fff;
}
.page__guide__inner section {
  margin-bottom: 100px;
}
.page__guide__inner__catch {
  margin-bottom: 30px;
  padding: 16px 30px;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(13, 12, 51, 0.5) 0%, rgba(44, 156, 224, 0.5) 100%);
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
.page__guide__inner__catch2 {
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.page__guide__inner__title {
  margin: 0 auto 1rem;
  max-width: 1200px;
  font-size: 24px;
  font-weight: 500;
}
.page__guide__inner__title a {
  color: #2D9CDB;
}
.page__guide__inner__title a:hover {
  text-decoration: none;
}
.page__guide__inner__text {
  margin: 0 auto 100px;
  max-width: 1200px;
  font-size: 18px;
}
.page__guide__inner__text a {
  color: #2D9CDB;
}
.page__guide__inner__text a:hover {
  text-decoration: none;
}
.page__guide__inner__nav {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0 auto 40px;
  padding: 0;
  max-width: 1100px;
}
.page__guide__inner__nav li {
  position: relative;
  width: 33.3333333333%;
  text-align: center;
  font-size: 18px;
  border-right: 1px solid #707070;
}
.page__guide__inner__nav li:first-child {
  border-left: 1px solid #707070;
}
.page__guide__inner__nav li a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px 0;
  color: #222;
  text-decoration: none;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__guide__inner__nav li a:hover {
  background-color: rgba(216, 216, 216, 0.3);
}
.page__guide__inner__nav li::after {
  position: absolute;
  bottom: 5px;
  left: 50%;
  display: block;
  content: "";
  margin: 0 auto;
  width: 5px;
  height: 5px;
  border-left: 1px solid #222;
  border-bottom: 1px solid #222;
  transform: translateX(-50%) rotateZ(-45deg);
}
.page__guide__inner__flow {
  margin: 0 auto 40px;
  max-width: 1200px;
  padding: 40px 50px 10px;
  background-color: rgba(88, 143, 199, 0.1);
}
.page__guide__inner__flow__title {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  color: #588FC7;
}
.page__guide__inner__flow__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 999px;
  background-color: #fff;
}
.page__guide__inner__flow__item__step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 999px;
  background-color: #588FC7;
  font-family: "Barlow", serif;
  font-weight: 700;
  font-size: 60px;
  line-height: 1;
  color: #fff;
}
.page__guide__inner__flow__item__step span {
  font-size: 20px;
}
.page__guide__inner__flow__item__detail {
  width: calc(100% - 130px);
}
.page__guide__inner__flow__item__detail__title {
  font-size: 22px;
  font-weight: 700;
  color: #588FC7;
}
.page__guide__inner__flow__item__detail__text {
  margin: 0;
  font-size: 16px;
}
.page__guide__inner__flow__arrow {
  margin: 20px auto;
  text-align: center;
}
.page__guide__inner__flow2 {
  margin: 0 auto 40px;
  max-width: 1200px;
  padding: 40px 50px;
  background: linear-gradient(180deg, rgba(88, 143, 199, 0.1) 0%, rgba(211, 101, 31, 0.1) 50%);
}
.page__guide__inner__flow2__title {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  color: #588FC7;
}
.page__guide__inner__flow2__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 999px;
  background-color: #fff;
}
.page__guide__inner__flow2__item__step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 999px;
  background-color: #588FC7;
  font-family: "Barlow", serif;
  font-weight: 700;
  font-size: 60px;
  line-height: 1;
  color: #fff;
}
.page__guide__inner__flow2__item__step span {
  font-size: 20px;
}
.page__guide__inner__flow2__item__detail {
  width: calc(100% - 130px);
}
.page__guide__inner__flow2__item__detail__title {
  font-size: 22px;
  font-weight: 700;
  color: #588FC7;
}
.page__guide__inner__flow2__item__detail__text {
  margin: 0;
  font-size: 16px;
}
.page__guide__inner__flow2__item.step4 .page__guide__inner__flow2__item__detail__title {
  color: #6A88AD;
}
.page__guide__inner__flow2__item.step4 .page__guide__inner__flow2__item__step {
  background-color: #6A88AD;
}
.page__guide__inner__flow2__item.step5 .page__guide__inner__flow2__item__detail__title {
  color: #858089;
}
.page__guide__inner__flow2__item.step5 .page__guide__inner__flow2__item__step {
  background-color: #858089;
}
.page__guide__inner__flow2__item.step6 .page__guide__inner__flow2__item__detail__title {
  color: #9E7868;
}
.page__guide__inner__flow2__item.step6 .page__guide__inner__flow2__item__step {
  background-color: #9E7868;
}
.page__guide__inner__flow2__item.step7 .page__guide__inner__flow2__item__detail__title {
  color: #AF7150;
}
.page__guide__inner__flow2__item.step7 .page__guide__inner__flow2__item__step {
  background-color: #AF7150;
}
.page__guide__inner__flow2__item.step8 .page__guide__inner__flow2__item__detail__title {
  color: #C56A32;
}
.page__guide__inner__flow2__item.step8 .page__guide__inner__flow2__item__step {
  background-color: #C56A32;
}
.page__guide__inner__flow2__item.step9 .page__guide__inner__flow2__item__detail__title {
  color: #D3651F;
}
.page__guide__inner__flow2__item.step9 .page__guide__inner__flow2__item__step {
  background-color: #D3651F;
}
.page__guide__inner__flow2__item.step10 .page__guide__inner__flow2__item__detail__title {
  color: #D3651F;
}
.page__guide__inner__flow2__item.step10 .page__guide__inner__flow2__item__step {
  background-color: #D3651F;
}
.page__guide__inner__flow2__arrow {
  margin: 20px auto;
  text-align: center;
}
.page__guide__inner__flow2__arrow.step4 svg g {
  fill: #6A88AD;
}
.page__guide__inner__flow2__arrow.step5 svg g {
  fill: #858089;
}
.page__guide__inner__flow2__arrow.step6 svg g {
  fill: #9E7868;
}
.page__guide__inner__flow2__arrow.step7 svg g {
  fill: #AF7150;
}
.page__guide__inner__flow2__arrow.step8 svg g {
  fill: #C56A32;
}
.page__guide__inner__flow2__arrow.step9 svg g {
  fill: #D3651F;
}
.page__guide__inner__faq {
  margin: 0 auto 40px;
  max-width: 1200px;
}
.page__guide__inner__faq__item {
  padding: 20px 0;
  border-bottom: 1px dotted #A9A9A9;
}
.page__guide__inner__faq__item__q {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  margin: 0;
  font-size: 22px;
  font-weight: 700;
}
.page__guide__inner__faq__item__q span {
  font-size: 40px;
  font-weight: 700;
  font-family: "Barlow", serif;
  color: #588FC7;
}
.page__guide__inner__faq__item__a {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  margin: 0;
  font-size: 18px;
}
.page__guide__inner__faq__item__a span {
  font-size: 40px;
  font-weight: 700;
  font-family: "Barlow", serif;
  color: #D3651F;
}
.page__guide__form {
  position: relative;
  max-width: 1900px;
  margin: 0 auto;
  padding: 50px 50px;
  border-radius: 20px;
  background-color: #EFEFF1;
}
.page__guide__form__title {
  margin-bottom: 60px;
  padding: 10px 30px;
  border-radius: 10px;
  background-color: #0D0C33;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
.page__guide__form__subTitle {
  max-width: 1200px;
  margin: 0 auto 20px;
  font-size: 24px;
  font-weight: 700;
  color: #1F1F43;
}
.page__guide__form__terms {
  overflow-y: scroll;
  max-width: 1200px;
  height: 430px;
  margin: 0 auto 20px;
  padding: 20px;
  border: 1px solid #707070;
  background-color: #fff;
}
.page__guide__form__terms h2 {
  font-size: 16px;
}
.page__guide__form__terms p {
  font-size: 16px;
}
.page__guide__form__terms ol li {
  font-size: 16px;
}

@media only screen and (max-width: 991px) {
  .page__guide {
    padding: 0px 30px 60px;
    padding: calc(0.15625 * 0 * 1vw) calc(0.15625 * 30 * 1vw) calc(0.15625 * 60 * 1vw);
  }
  .page__guide__title {
    margin: 0px 0px 40px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 40 * 1vw);
  }
  .page__guide__title__en {
    font-size: 110px;
    font-size: calc(0.15625 * 110 * 1vw);
  }
  .page__guide__title__jp {
    font-size: 60px;
    font-size: calc(0.15625 * 60 * 1vw);
  }
  .page__guide__title__jp span {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__guide__catch {
    margin: 0px 0px 50px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 50 * 1vw);
    font-size: 26px;
    font-size: calc(0.15625 * 26 * 1vw);
    text-align: left;
  }
  .page__guide__catch br {
    display: none;
  }
  .page__guide__tabs li {
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
  }
  .page__guide__tabs li a {
    width: 100%;
    height: 60px;
    height: calc(0.15625 * 60 * 1vw);
    line-height: 60px;
    line-height: calc(0.15625 * 60 * 1vw);
  }
  .page__guide__tabs li.current a {
    width: 100%;
    height: 70px;
    height: calc(0.15625 * 70 * 1vw);
    line-height: 70px;
    line-height: calc(0.15625 * 70 * 1vw);
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__guide__inner {
    margin: 0px 0px 50px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 50 * 1vw);
    padding: 30px 20px;
    padding: calc(0.15625 * 30 * 1vw) calc(0.15625 * 20 * 1vw);
  }
  .page__guide__inner section {
    margin: 0px 0px 50px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 50 * 1vw);
  }
  .page__guide__inner__catch {
    margin: 0px 0px 30px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 30 * 1vw);
    padding: 10px 15px;
    padding: calc(0.15625 * 10 * 1vw) calc(0.15625 * 15 * 1vw);
    font-size: 30px;
    font-size: calc(0.15625 * 30 * 1vw);
  }
  .page__guide__inner__catch2 {
    margin: 0px 0px 20px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 20 * 1vw);
    font-size: 24px;
    font-size: calc(0.15625 * 24 * 1vw);
    text-align: left;
  }
  .page__guide__inner__title {
    margin: 0px 0px 20px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 20 * 1vw);
    font-size: 24px;
    font-size: calc(0.15625 * 24 * 1vw);
  }
  .page__guide__inner__text {
    margin: 0px 0px 50px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 50 * 1vw);
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .page__guide__inner__nav {
    margin: 0px 0px 30px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 30 * 1vw);
    flex-wrap: wrap;
    justify-content: flex-start;
    border-left: 1px solid #707070;
  }
  .page__guide__inner__nav li {
    width: 33.3333333333%;
    font-size: 14px;
    font-size: calc(0.15625 * 14 * 1vw);
  }
  .page__guide__inner__nav li:first-child {
    border-left: none;
  }
  .page__guide__inner__nav li a {
    padding: 20px 0px;
    padding: calc(0.15625 * 20 * 1vw) calc(0.15625 * 0 * 1vw);
  }
  .page__guide__inner__nav li::after {
    width: 8px;
    width: calc(0.15625 * 8 * 1vw);
    height: 8px;
    height: calc(0.15625 * 8 * 1vw);
  }
  .page__guide__inner__flow {
    margin: 0px 0px 50px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 50 * 1vw);
    padding: 30px 20px 5px;
    padding: calc(0.15625 * 30 * 1vw) calc(0.15625 * 20 * 1vw) calc(0.15625 * 5 * 1vw);
  }
  .page__guide__inner__flow__title {
    font-size: 24px;
    font-size: calc(0.15625 * 24 * 1vw);
  }
  .page__guide__inner__flow__item {
    align-items: center;
  }
  .page__guide__inner__flow__item__step {
    width: 100px;
    width: calc(0.15625 * 100 * 1vw);
    height: 100px;
    height: calc(0.15625 * 100 * 1vw);
    font-size: 60px;
    font-size: calc(0.15625 * 60 * 1vw);
  }
  .page__guide__inner__flow__item__step span {
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .page__guide__inner__flow__item__detail {
    width: 75%;
  }
  .page__guide__inner__flow__item__detail__title {
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .page__guide__inner__flow__item__detail__text {
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__guide__inner__flow__arrow {
    margin: 10px 0px;
    margin: calc(0.15625 * 10 * 1vw) calc(0.15625 * 0 * 1vw);
  }
  .page__guide__inner__flow2 {
    margin: 0px 0px 50px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 50 * 1vw);
    padding: 30px 20px;
    padding: calc(0.15625 * 30 * 1vw) calc(0.15625 * 20 * 1vw);
  }
  .page__guide__inner__flow2__title {
    font-size: 24px;
    font-size: calc(0.15625 * 24 * 1vw);
  }
  .page__guide__inner__flow2__item {
    align-items: center;
  }
  .page__guide__inner__flow2__item__step {
    width: 100px;
    width: calc(0.15625 * 100 * 1vw);
    height: 100px;
    height: calc(0.15625 * 100 * 1vw);
    font-size: 60px;
    font-size: calc(0.15625 * 60 * 1vw);
  }
  .page__guide__inner__flow2__item__step span {
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .page__guide__inner__flow2__item__detail {
    width: 75%;
  }
  .page__guide__inner__flow2__item__detail__title {
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .page__guide__inner__flow2__item__detail__text {
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__guide__inner__flow2__arrow {
    margin: 10px 0px;
    margin: calc(0.15625 * 10 * 1vw) calc(0.15625 * 0 * 1vw);
  }
  .page__guide__inner__faq {
    margin: 0;
  }
  .page__guide__inner__faq__item {
    padding: 15px 0px;
    padding: calc(0.15625 * 15 * 1vw) calc(0.15625 * 0 * 1vw);
  }
  .page__guide__inner__faq__item__q {
    margin: 0;
    gap: 15px;
    gap: calc(0.15625 * 15 * 1vw);
    font-size: 22px;
    font-size: calc(0.15625 * 22 * 1vw);
  }
  .page__guide__inner__faq__item__q span {
    font-size: 40px;
    font-size: calc(0.15625 * 40 * 1vw);
  }
  .page__guide__inner__faq__item__a {
    margin: 0;
    gap: 15px;
    gap: calc(0.15625 * 15 * 1vw);
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__guide__inner__faq__item__a span {
    font-size: 40px;
    font-size: calc(0.15625 * 40 * 1vw);
  }
  .page__guide__form {
    padding: 30px 20px;
    padding: calc(0.15625 * 30 * 1vw) calc(0.15625 * 20 * 1vw);
  }
  .page__guide__form__title {
    margin: 0px 0px 30px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 30 * 1vw);
    padding: 10px 30px;
    padding: calc(0.15625 * 10 * 1vw) calc(0.15625 * 30 * 1vw);
    font-size: 30px;
    font-size: calc(0.15625 * 30 * 1vw);
  }
  .page__guide__form__subTitle {
    margin: 0px 0px 20px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 20 * 1vw);
    font-size: 24px;
    font-size: calc(0.15625 * 24 * 1vw);
  }
  .page__guide__form__terms {
    margin: 0px 0px 20px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 20 * 1vw);
    padding: 20px 20px;
    padding: calc(0.15625 * 20 * 1vw) calc(0.15625 * 20 * 1vw);
  }
  .page__guide__form__terms h2 {
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .page__guide__form__terms p {
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__guide__form__terms p strong {
    font-size: 20px;
    font-size: calc(0.15625 * 20 * 1vw);
  }
  .page__guide__form__terms ol li {
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
}
.page__results {
  padding: 0 50px 100px;
}
.page__results__inner {
  max-width: 1900px;
  margin: 0 auto 0;
  padding: 50px 50px;
  border-radius: 20px;
  background-color: #fff;
}
.page__results__inner__title {
  position: relative;
  margin-bottom: 40px;
  text-align: center;
}
.page__results__inner__title__en {
  display: inline-block;
  font-size: 140px;
  font-weight: 600;
  font-family: "Barlow", serif;
  line-height: 1;
  background: linear-gradient(90deg, rgba(235, 87, 85, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__results__inner__title__jp {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 70px;
  font-weight: 700;
  line-height: 1;
}
.page__results__inner__title__jp span {
  display: block;
  font-size: 18px;
}
.page__results__inner__title.color2 .page__results__inner__title__en {
  background: linear-gradient(90deg, rgba(242, 153, 83, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__results__inner__title.color3 .page__results__inner__title__en {
  background: linear-gradient(90deg, rgba(185, 107, 220, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__results__inner__title.color4 .page__results__inner__title__en {
  background: linear-gradient(90deg, rgba(190, 189, 189, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__results__inner__catch {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 16px 30px;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(13, 12, 51, 0.5) 0%, rgba(44, 156, 224, 0.5) 100%);
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
.page__results__inner__catch span {
  display: block;
  min-width: 100px;
  padding: 4px 10px;
  border: 1px solid #fff;
  background-color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #333;
}
.page__results__inner__catch span.cf a {
  background-color: #219053;
}
.page__results__inner__catch span.mv a {
  background-color: #303065;
}
.page__results__inner__catch span.pv a {
  background-color: #ff8b8b;
}
.page__results__inner__catch span.vtr a {
  background-color: #632980;
}
.page__results__inner__catch span.web a {
  background-color: #f2994a;
}
.page__results__inner__catch span.web-cm a {
  background-color: #f2994a;
}
.page__results__inner__catch span.web-movie a {
  background-color: #f2994a;
}
.page__results__inner__catch span.uncategorized a, .page__results__inner__catch span.other a {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}
.page__results__inner__catch span.still-picture a {
  background-color: #f2994a;
}
.page__results__inner__catch span.drama a {
  background-color: #2d9cdb;
}
.page__results__inner__catch span.variety a {
  background-color: #C3B83B;
}
.page__results__inner__catch span.information a {
  background-color: #bdbdbd;
}
.page__results__inner__catch span.information-program a {
  background-color: #bdbdbd;
}
.page__results__inner__catch span.movie a {
  background-color: #eb5757;
}
.page__results__inner__catch span.magazine a {
  background-color: #f2994a;
}
.page__results__inner__catch2 {
  margin-bottom: 50px;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  line-height: 1.8;
}
.page__results__inner__content {
  font-size: 16px;
  font-weight: 400;
}
.page__results__inner__content .wp-block-image {
  margin-bottom: 2rem;
}
.page__results__inner__content .wp-block-image figcaption {
  font-size: 14px;
  text-align: center;
}
.page__results__inner__content p {
  font-size: 18px;
  line-height: 1.75;
  font-weight: 400;
  margin-bottom: 2rem;
}
.page__results__inner__content h2 {
  font-size: 26px;
  font-weight: 700;
}
.page__results__inner__content h3 {
  font-size: 22px;
  font-weight: 700;
}
.page__results__inner__category {
  position: relative;
  margin-bottom: 60px;
  padding: 30px 0;
  border-radius: 10px;
  background-color: rgba(245, 245, 245, 0.5);
}
.page__results__inner__category__title {
  position: absolute;
  top: 0;
  left: 50%;
  margin: 0;
  transform: translateX(-50%) translateY(-50%);
  font-size: 20px;
  font-weight: 700;
}
.page__results__inner__category__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
  margin: 0 auto;
  padding: 0 20px;
}
.page__results__inner__category__list li {
  width: 16.6666666667%;
  text-align: center;
  font-size: 18px;
  border-left: 1px solid #E3E3E3;
}
.page__results__inner__category__list li:nth-child(6n) {
  border-right: 1px solid #E3E3E3;
}
.page__results__inner__category__list li a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px 0;
  color: #222;
  text-decoration: none;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__results__inner__category__list li a:hover {
  background-color: rgba(216, 216, 216, 0.3);
}
.page__results__inner__list {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
.page__results__inner__list__item {
  width: calc((100% - 80px) / 3);
}
.page__results__inner__list__item__image {
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;
  border-radius: 10px;
  aspect-ratio: 88/56;
}
.page__results__inner__list__item__image__annotation {
  position: absolute;
  z-index: 1;
  font-size: 14px;
  right: 10px;
  bottom: 10px;
  margin: 0;
  color: #fff;
}
.page__results__inner__list__item__image img {
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
  object-fit: cover;
  height: 100%;
}
.page__results__inner__list__item__image a {
  display: block;
  position: relative;
  overflow: hidden;
  aspect-ratio: 88/56;
}
.page__results__inner__list__item__image a::before {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 12, 52, 0.5);
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__results__inner__list__item__image a img {
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
  height: 100%;
}
.page__results__inner__list__item__image a:hover::before {
  opacity: 1;
}
.page__results__inner__list__item__image a:hover::after {
  opacity: 1;
}
.page__results__inner__list__item__image a:hover img {
  transform: scale(1.05);
}
.page__results__inner__list__item__place {
  margin-bottom: 30px;
  font-size: 17px;
  font-weight: 500;
  color: #808080;
}
.page__results__inner__list__item__category {
  display: flex;
  margin-bottom: 10px;
  font-size: 18px;
}
.page__results__inner__list__item__category a {
  display: block;
  min-width: 100px;
  padding: 4px 10px;
  background-color: #fff;
  border: 1px solid #fff;
  text-decoration: none;
  text-align: center;
  color: #fff;
}
.page__results__inner__list__item__category.cf a {
  background-color: #219053;
}
.page__results__inner__list__item__category.mv a {
  background-color: #303065;
}
.page__results__inner__list__item__category.pv a {
  background-color: #ff8b8b;
}
.page__results__inner__list__item__category.vtr a {
  background-color: #632980;
}
.page__results__inner__list__item__category.web a {
  background-color: #f2994a;
}
.page__results__inner__list__item__category.web-cm a {
  background-color: #f2994a;
}
.page__results__inner__list__item__category.web-movie a {
  background-color: #f2994a;
}
.page__results__inner__list__item__category.uncategorized a, .page__results__inner__list__item__category.other a {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}
.page__results__inner__list__item__category.still-picture a {
  background-color: #f2994a;
}
.page__results__inner__list__item__category.drama a {
  background-color: #2d9cdb;
}
.page__results__inner__list__item__category.variety a {
  background-color: #C3B83B;
}
.page__results__inner__list__item__category.information a {
  background-color: #bdbdbd;
}
.page__results__inner__list__item__category.information-program a {
  background-color: #bdbdbd;
}
.page__results__inner__list__item__category.movie a {
  background-color: #eb5757;
}
.page__results__inner__list__item__category.magazine a {
  background-color: #f2994a;
}
.page__results__inner__list__item__title {
  margin: 0;
  font-size: 18px;
}
.page__results__inner__list__item__title a {
  color: #222222;
  text-decoration: none;
}
.page__results__inner__list__item__title a:hover {
  text-decoration: underline;
}
.page__results__inner__detail {
  margin: 0 auto 18px;
}
.page__results__inner__detail__image {
  overflow: hidden;
  margin: 0 auto 0;
  text-align: center;
}
.page__results__inner__detail__image img {
  height: auto;
  border-radius: 10px;
}
.page__results__inner__detail__title {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}
.page__results__inner__detail__text {
  font-size: 16px;
  line-height: 1.75;
}
.page__results__inner__thumb {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  margin: 0 auto 80px;
}
.page__results__inner__thumb__item {
  width: calc((100% - 54px) / 4);
}
.page__results__inner__thumb__item__image {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.page__results__inner__thumb__item__image img {
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__results__inner__thumb__item__image::before {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 12, 52, 0.5);
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__results__inner__thumb__item__image:hover::before {
  opacity: 1;
}
.page__results__inner__thumb__item__image:hover img {
  transform: scale(1.05);
}
.page__results__inner__thumb__item.current .page__scene__inner__thumb__item__image::before {
  opacity: 1;
}
.page__results__inner__thumb__item.current .page__scene__inner__thumb__item__image img {
  transform: scale(1.05);
}
.page__results__inner__thumb__item__title {
  font-size: 16px;
  line-height: 1.75;
  text-align: center;
}
.page__results__inner__thumb__item__hidden {
  display: none;
}

@media only screen and (max-width: 991px) {
  .page__results {
    padding: 0px 30px 100px;
    padding: calc(0.15625 * 0 * 1vw) calc(0.15625 * 30 * 1vw) calc(0.15625 * 100 * 1vw);
    background-size: 140% auto;
    background-position: center top;
  }
  .page__results__inner {
    margin: 0;
    padding: 30px 20px;
    padding: calc(0.15625 * 30 * 1vw) calc(0.15625 * 20 * 1vw);
  }
  .page__results__inner__title {
    margin: 0px 0px 40px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 40 * 1vw);
  }
  .page__results__inner__title__en {
    font-size: 110px;
    font-size: calc(0.15625 * 110 * 1vw);
  }
  .page__results__inner__title__jp {
    font-size: 45px;
    font-size: calc(0.15625 * 45 * 1vw);
  }
  .page__results__inner__title__jp span {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__results__inner__catch {
    padding: 10px 15px;
    padding: calc(0.15625 * 10 * 1vw) calc(0.15625 * 15 * 1vw);
    font-size: 30px;
    font-size: calc(0.15625 * 30 * 1vw);
  }
  .page__results__inner__catch span {
    min-width: 12vw;
    font-size: 14px;
    font-size: calc(0.15625 * 14 * 1vw);
    padding: 5px 8px;
    padding: calc(0.15625 * 5 * 1vw) calc(0.15625 * 8 * 1vw);
  }
  .page__results__inner__catch2 {
    margin: 0px 0px 40px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 40 * 1vw);
    font-size: 22px;
    font-size: calc(0.15625 * 22 * 1vw);
  }
  .page__results__inner__content {
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__results__inner__content .wp-block-image figcaption {
    font-size: 14px;
    font-size: calc(0.15625 * 14 * 1vw);
  }
  .page__results__inner__content p {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__results__inner__content h2 {
    font-size: 26px;
    font-size: calc(0.15625 * 26 * 1vw);
  }
  .page__results__inner__content h3 {
    font-size: 22px;
    font-size: calc(0.15625 * 22 * 1vw);
  }
  .page__results__inner__category {
    padding: 20px 10px 20px;
    padding: calc(0.15625 * 20 * 1vw) calc(0.15625 * 10 * 1vw) calc(0.15625 * 20 * 1vw);
  }
  .page__results__inner__category__title {
    position: static;
    font-size: 22px;
    font-size: calc(0.15625 * 22 * 1vw);
    margin: 0px 0px 20px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 20 * 1vw);
    transform: none;
    text-align: center;
  }
  .page__results__inner__category__list {
    flex-wrap: wrap;
  }
  .page__results__inner__category__list li {
    width: 33.3333333333%;
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__results__inner__category__list li:nth-child(3n) {
    border-right: 1px solid #E3E3E3;
  }
  .page__results__inner__category__list li a {
    padding: 5px 0px;
    padding: calc(0.15625 * 5 * 1vw) calc(0.15625 * 0 * 1vw);
  }
  .page__results__inner__list {
    justify-content: space-between;
    gap: 30px 0px;
    gap: calc(0.15625 * 30 * 1vw) calc(0.15625 * 0 * 1vw);
  }
  .page__results__inner__list__item {
    width: 48%;
  }
  .page__results__inner__list__item__image {
    margin: 0px 0px 5px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 5 * 1vw);
  }
  .page__results__inner__list__item__image__annotation {
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__results__inner__list__item__place {
    margin: 0px 0px 20px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 20 * 1vw);
    font-size: 17px;
    font-size: calc(0.15625 * 17 * 1vw);
  }
  .page__results__inner__list__item__category {
    margin: 0px 0px 5px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 5 * 1vw);
    font-size: 14px;
    font-size: calc(0.15625 * 14 * 1vw);
  }
  .page__results__inner__list__item__category a {
    min-width: 12vw;
    padding: 5px 8px;
    padding: calc(0.15625 * 5 * 1vw) calc(0.15625 * 8 * 1vw);
  }
  .page__results__inner__list__item__title {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__results__inner__detail {
    margin: 0px auto 20px;
    margin: calc(0.15625 * 0 * 1vw) auto calc(0.15625 * 20 * 1vw);
  }
  .page__results__inner__detail__image {
    margin: 0px auto 20px;
    margin: calc(0.15625 * 0 * 1vw) auto calc(0.15625 * 20 * 1vw);
  }
  .page__results__inner__detail__title {
    margin: 0px auto 20px;
    margin: calc(0.15625 * 0 * 1vw) auto calc(0.15625 * 20 * 1vw);
    font-size: 30px;
    font-size: calc(0.15625 * 30 * 1vw);
  }
  .page__results__inner__detail__text {
    margin: 0px auto 20px;
    margin: calc(0.15625 * 0 * 1vw) auto calc(0.15625 * 20 * 1vw);
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__results__inner__thumb {
    margin: 0px auto 80px;
    margin: calc(0.15625 * 0 * 1vw) auto calc(0.15625 * 80 * 1vw);
    gap: 0 5%;
  }
  .page__results__inner__thumb__item {
    width: 30%;
  }
  .page__results__inner__thumb__item.current .page__scene__inner__thumb__item__image::before {
    opacity: 1;
  }
  .page__results__inner__thumb__item.current .page__scene__inner__thumb__item__image img {
    transform: scale(1.05);
  }
  .page__results__inner__thumb__item__title {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
}
.page__contact {
  padding: 0 50px 100px;
}
.page__contact__inner {
  max-width: 1900px;
  margin: 0 auto 0;
  padding: 50px 50px;
  border-radius: 20px;
  background-color: #fff;
}
.page__contact__inner__title {
  position: relative;
  margin-bottom: 40px;
  text-align: center;
}
.page__contact__inner__title__en {
  display: inline-block;
  font-size: 140px;
  font-weight: 600;
  font-family: "Barlow", serif;
  line-height: 1;
  background: linear-gradient(90deg, rgba(235, 87, 85, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__contact__inner__title__jp {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 70px;
  font-weight: 700;
  line-height: 1;
}
.page__contact__inner__title__jp span {
  display: block;
  font-size: 18px;
}
.page__contact__inner__title.color2 .page__contact__inner__title__en {
  background: linear-gradient(90deg, rgba(242, 153, 83, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__contact__inner__title.color3 .page__contact__inner__title__en {
  background: linear-gradient(90deg, rgba(185, 107, 220, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__contact__inner__title.color4 .page__contact__inner__title__en {
  background: linear-gradient(90deg, rgba(190, 189, 189, 0.2) 0%, rgba(115, 172, 204, 0.2) 61%, rgba(44, 156, 218, 0.2) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page__contact__inner__catch {
  margin-bottom: 50px;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  line-height: 1.8;
}
.page__contact__inner__complete {
  font-size: 22px;
  text-align: center;
  color: #E85757;
}
.page__contact__inner__form {
  max-width: 1100px;
  margin: 0 auto;
}
.page__contact__inner__form__note {
  font-size: 15px;
}
.page__contact__inner__form__note span {
  color: #E85757;
}
.page__contact__inner__form__check {
  font-size: 22px;
  text-align: center;
  color: #E85757;
}
.page__contact__inner__form__dl {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  padding: 2px;
  border: 1px solid #E8E9ED;
  border-radius: 10px;
  background-color: #fff;
}
.page__contact__inner__form__dl__dt {
  display: flex;
  align-items: center;
  width: 350px;
  padding: 30px 25px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(240, 240, 242, 0.5);
  font-size: 20px;
  font-weight: 500;
}
.page__contact__inner__form__dl__dt span {
  color: #ED5559;
}
.page__contact__inner__form__dl__dd {
  width: calc(100% - 350px);
  padding: 30px 25px;
}
.page__contact__inner__form__dl__dd input[type=text],
.page__contact__inner__form__dl__dd input[type=tel],
.page__contact__inner__form__dl__dd input[type=email] {
  width: 100%;
  padding: 8px;
  border: 1px solid #A9A9A9;
  border-radius: 4px;
  font-size: 20px;
}
.page__contact__inner__form__dl__dd input[type=text].text-s,
.page__contact__inner__form__dl__dd input[type=tel].text-s,
.page__contact__inner__form__dl__dd input[type=email].text-s {
  width: 100px;
}
.page__contact__inner__form__dl__dd__notice {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  color: #E85757;
}
.page__contact__inner__form__dl__dd__medium {
  width: 100%;
  height: 100px;
  padding: 8px;
  border: 1px solid #A9A9A9;
  border-radius: 4px;
  font-size: 20px;
}
.page__contact__inner__form__dl__dd__large {
  width: 100%;
  height: 245px;
  padding: 8px;
  border: 1px solid #A9A9A9;
  border-radius: 4px;
  font-size: 20px;
}
.page__contact__inner__form__dl__dd .error {
  font-size: 18px;
  font-weight: 400;
  color: #E85757;
}
.page__contact__inner__form__buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.page__contact__inner__form__button {
  display: block;
  position: relative;
  width: 380px;
  height: 80px;
  background-color: #707070;
  clip-path: polygon(0% 0%, 92% 0, 100% 50%, 92% 100%, 0% 100%);
  font-size: 20px;
  line-height: 80px;
  font-weight: 700;
  text-align: center;
}
.page__contact__inner__form__button button {
  display: block;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  position: relative;
  text-decoration: none;
  color: #222;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__contact__inner__form__button button::after {
  content: "";
  width: 10px;
  height: 10px;
  border: 0;
  border-top: solid 1px #222;
  border-right: solid 1px #222;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  right: 24px;
  bottom: 0;
  margin: auto;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__contact__inner__form__button button:hover {
  background-color: #707070;
  color: #fff;
}
.page__contact__inner__form__button::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 378px;
  height: 78px;
  clip-path: polygon(0% 0%, 92% 0, 100% 50%, 92% 100%, 0% 100%);
  background-color: #fff;
}
.page__contact__inner__form__back {
  display: block;
  position: relative;
  width: 280px;
  height: 80px;
  background-color: #707070;
  clip-path: polygon(100% 0, 100% 100%, 12% 100%, 0 50%, 12% 0);
  font-size: 20px;
  line-height: 80px;
  font-weight: 700;
  text-align: center;
}
.page__contact__inner__form__back button {
  display: block;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  position: relative;
  text-decoration: none;
  color: #222;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__contact__inner__form__back button::after {
  content: "";
  width: 10px;
  height: 10px;
  border: 0;
  border-top: solid 1px #222;
  border-right: solid 1px #222;
  transform: rotate(-135deg);
  position: absolute;
  top: 0;
  left: 24px;
  bottom: 0;
  margin: auto;
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
}
.page__contact__inner__form__back button:hover {
  background-color: #707070;
  color: #fff;
}
.page__contact__inner__form__back::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 278px;
  height: 78px;
  clip-path: polygon(100% 0, 100% 100%, 12% 100%, 0 50%, 12% 0);
  background-color: #fff;
}

@media only screen and (max-width: 991px) {
  .page__contact {
    padding: 0px 30px 100px;
    padding: calc(0.15625 * 0 * 1vw) calc(0.15625 * 30 * 1vw) calc(0.15625 * 100 * 1vw);
    background-size: 140% auto;
    background-position: center top;
  }
  .page__contact__inner {
    margin: 0;
    padding: 30px 20px;
    padding: calc(0.15625 * 30 * 1vw) calc(0.15625 * 20 * 1vw);
  }
  .page__contact__inner__title {
    margin: 0px 0px 40px;
    margin: calc(0.15625 * 0 * 1vw) calc(0.15625 * 0 * 1vw) calc(0.15625 * 40 * 1vw);
  }
  .page__contact__inner__title__en {
    font-size: 110px;
    font-size: calc(0.15625 * 110 * 1vw);
  }
  .page__contact__inner__title__jp {
    font-size: 45px;
    font-size: calc(0.15625 * 45 * 1vw);
  }
  .page__contact__inner__title__jp span {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
  }
  .page__contact__inner__catch {
    font-size: 22px;
    font-size: calc(0.15625 * 22 * 1vw);
    text-align: left;
  }
  .page__contact__inner__catch br {
    display: none;
  }
  .page__contact__inner__complete {
    font-size: 22px;
    font-size: calc(0.15625 * 22 * 1vw);
  }
  .page__contact__inner__form__note {
    font-size: 15px;
    font-size: calc(0.15625 * 15 * 1vw);
  }
  .page__contact__inner__form__check {
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
    text-align: left;
  }
  .page__contact__inner__form__dl__dt {
    width: 30%;
    padding: 20px 15px;
    padding: calc(0.15625 * 20 * 1vw) calc(0.15625 * 15 * 1vw);
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__contact__inner__form__dl__dd {
    width: 70%;
    padding: 20px 15px;
    padding: calc(0.15625 * 20 * 1vw) calc(0.15625 * 15 * 1vw);
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__contact__inner__form__dl__dd input[type=text],
  .page__contact__inner__form__dl__dd input[type=tel],
  .page__contact__inner__form__dl__dd input[type=email] {
    padding: 8px;
    padding: calc(0.15625 * 8 * 1vw);
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__contact__inner__form__dl__dd input[type=text].text-s,
  .page__contact__inner__form__dl__dd input[type=tel].text-s,
  .page__contact__inner__form__dl__dd input[type=email].text-s {
    width: 12vw;
  }
  .page__contact__inner__form__dl__dd__medium {
    padding: 8px;
    padding: calc(0.15625 * 8 * 1vw);
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__contact__inner__form__dl__dd__large {
    padding: 8px;
    padding: calc(0.15625 * 8 * 1vw);
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__contact__inner__form__dl__dd__notice {
    margin-top: 2vw;
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__contact__inner__form__dl__dd .error {
    font-size: 16px;
    font-size: calc(0.15625 * 16 * 1vw);
  }
  .page__contact__inner__form__button {
    width: 320px;
    width: calc(0.15625 * 320 * 1vw);
    height: 70px;
    height: calc(0.15625 * 70 * 1vw);
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
    line-height: 70px;
    line-height: calc(0.15625 * 70 * 1vw);
  }
  .page__contact__inner__form__button button::after {
    right: 3vw;
    width: 12px;
    width: calc(0.15625 * 12 * 1vw);
    height: 12px;
    height: calc(0.15625 * 12 * 1vw);
  }
  .page__contact__inner__form__button::before {
    width: 317px;
    width: calc(0.15625 * 317 * 1vw);
    height: 67px;
    height: calc(0.15625 * 67 * 1vw);
  }
  .page__contact__inner__form__back {
    width: 170px;
    width: calc(0.15625 * 170 * 1vw);
    height: 70px;
    height: calc(0.15625 * 70 * 1vw);
    font-size: 18px;
    font-size: calc(0.15625 * 18 * 1vw);
    line-height: 70px;
    line-height: calc(0.15625 * 70 * 1vw);
    clip-path: polygon(100% 0, 100% 100%, 15% 100%, 0 50%, 15% 0);
  }
  .page__contact__inner__form__back button::after {
    left: 3vw;
    width: 12px;
    width: calc(0.15625 * 12 * 1vw);
    height: 12px;
    height: calc(0.15625 * 12 * 1vw);
  }
  .page__contact__inner__form__back::before {
    width: 167px;
    width: calc(0.15625 * 167 * 1vw);
    height: 67px;
    height: calc(0.15625 * 67 * 1vw);
    clip-path: polygon(100% 0, 100% 100%, 15% 100%, 0 50%, 15% 0);
  }
}