@charset "utf-8";
@use '../common' as *;
@use '../mixins' as *;

.page{
    &__scene{
        padding: 0 50px;
        background-size: 100% auto;
        background-repeat: no-repeat;
        &.train{
            background-image: url(/assets/img/scene/train/bg.jpg);
        }
        &.station{
            background-image: url(/assets/img/scene/station/bg.jpg);
        }
        &.bus{
            background-image: url(/assets/img/scene/bus/bg.jpg);
        }
        &.shopping{
            background-image: url(/assets/img/scene/shopping/bg.jpg);
        }
        &.office{
            background-image: url(/assets/img/scene/office/bg.jpg);
        }
        &.hotel{
            background-image: url(/assets/img/scene/hotel/bg.jpg);
        }
        &__inner{
            max-width: 1900px;
            margin: 100px auto 0;
            padding: 50px 50px;
            border-radius: 20px;
            background-color: #fff;
            &__title{
                position: relative;
                margin-bottom: 40px;
                text-align: center;
                &__en{
                    display: inline-block;
                    font-size: 140px;
                    font-weight: 600;
                    font-family: $fontB;
                    line-height: 1;
                    background: linear-gradient(90deg, rgba(235,87,85,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                &__jp{
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    font-size: 70px;
                    font-weight: 700;
                    line-height: 1;
                    span{
                        display: block;
                        font-size: 18px;
                    }
                }
                &.color2 &__en {
                    background: linear-gradient(90deg, rgba(242,153,83,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                &.color3 &__en {
                    background: linear-gradient(90deg, rgba(185,107,220,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                &.color4 &__en {
                    background: linear-gradient(90deg, rgba(190,189,189,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            &__catch{
                padding: 16px 30px;
                border-radius: 10px;
                background: linear-gradient(90deg, rgba(13,12,51,0.5) 0%,rgba(44,156,224,0.5) 100%);
                font-size: 30px;
                font-weight: 700;
                color: #fff;
            }
            &__catch2{
                margin-bottom: 40px;
                font-size: 30px;
                font-weight: 700;
                text-align: center;
            }
            &__note{
                font-size: 16px;
                text-align: right;
            }
            &__subTitle{
                margin-bottom: 10px;
                font-size: 30px;
                font-weight: 700;
            }
            &__text{
                margin-bottom: 80px;
                font-size: 18px;
            }
            &__nav{
                display: flex;
                justify-content: center;
                list-style: none;
                margin: 0 0 70px;
                padding: 0;
                li{
                    width: 174px;
                    text-align: center;
                    font-size: 18px;
                    border-right: 1px solid #707070;
                    &:first-child{
                        border-left: 1px solid #707070;
                    }
                    a{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        padding: 5px 0;
                        color: #222;
                        text-decoration: none;
                        @include transition-effect();
                        &:hover{
                            background-color: rgba($color: #D8D8D8, $alpha: .3);
                        }
                    }
                    &.current{
                        a{
                            background-color: rgba($color: #D8D8D8, $alpha: .3);
                        }
                    }
                }
            }
            &__list{
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                margin: 0 auto;
                &__item{
                    position: relative;
                    width: calc((100% - 80px)/3);
                    padding-bottom: 80px;
                    &__image{
                        overflow: hidden;
                        border-radius: 10px;
                        margin-bottom: 10px;
                        img{
                            @include transition-effect();
                            height: auto;
                        }
                        a{
                            display: block;
                            position: relative;
                            overflow: hidden;
                            &::before{
                                content: '';
                                display: block;
                                position: absolute;
                                opacity: 0;
                                z-index: 1;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: rgba($color: #0C0C34, $alpha: .5);
                                @include transition-effect();
                            }
                            &:hover{
                                &::before{
                                    opacity: 1;
                                }
                                img{
                                    transform: scale(1.05);
                                }
                            }
                        }
                    }
                    &__title{
                        font-size: 22px;
                        font-weight: 700;
                        a{
                            color: #222;
                            text-decoration: none;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                    &__subTitle{
                        font-size: 22px;
                        font-weight: 400;
                        text-align: center;
                        a{
                            color: #222;
                            text-decoration: none;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                    &__text{
                        font-size: 18px;
                        font-weight: 400;
                    }
                    &__link{
                        position: absolute;
                        left: 0;
                        bottom: 20px;
                        width: 120px;
                        margin: 0;
                        font-size: 16px;
                        font-weight: 700;
                        a{
                            display: block;
                            position: relative;
                            padding: 5px 0;
                            border-bottom: 1px dotted #707070;
                            text-decoration: none;
                            color: #222;
                            &::after{
                                content: '';
                                width: 8px;
                                height: 8px;
                                border: 0;
                                border-top: solid 1px #333;
                                border-right: solid 1px #333;
                                transform: rotate(45deg);
                                position: absolute;
                                top: 0;
                                right: 5px;
                                bottom: 0;
                                margin: auto;
                                @include transition-effect();
                            }
                            &:hover{
                                &::after{
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }
            &__detail{
                margin: 0 auto 18px;
                &__image{
                    overflow: hidden;
                    border-radius: 10px;
                    margin: 0 auto 0;
                }
                &__title{
                    margin-bottom: 20px;
                    font-size: 30px;
                    font-weight: 500;
                    text-align: center;
                }
                &__text{
                    font-size: 16px;
                    line-height: 1.75;
                }
            }
            &__thumb{
                display: flex;
                flex-wrap: wrap;
                gap: 18px;
                margin: 0 auto;
                &__item{
                    width: calc((100% - 54px)/4);
                    &__image{
                        cursor: pointer;
                        position: relative;
                        overflow: hidden;
                        border-radius: 10px;
                        img{
                            @include transition-effect();
                        }
                        &::before{
                            content: '';
                            display: block;
                            position: absolute;
                            opacity: 0;
                            z-index: 1;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba($color: #0C0C34, $alpha: .5);
                            @include transition-effect();
                        }
                        &:hover{
                            &::before{
                                opacity: 1;
                            }
                            img{
                                transform: scale(1.05);
                            }
                        }
                    }
                    &.current{
                        .page__scene__inner__thumb__item__image{
                            &::before{
                                opacity: 1;
                            }
                            img{
                                transform: scale(1.05);
                            }
                        }
                    }
                    &__title{
                        font-size: 16px;
                        line-height: 1.75;
                        text-align: center;
                    }
                    &__hidden{
                        display: none;
                    }
                }
            }
            &__notice{
                max-width: 950px;
                margin: 0 auto 60px;
                &__title{
                    margin-bottom: 20px;
                    font-size: 20px;
                    font-weight: 700;
                    span{
                        background: linear-gradient(to right,#F8E4D8 0%, #D6EAF7 100%);
                        background-repeat: no-repeat;
                        background-position: left bottom;
                        background-size: 100% 50%;
                    }
                }
                ul{
                    margin: 0 0 20px;
                    padding: 0;
                    list-style: none;
                    li{
                        position: relative;
                        padding-left: 1em;
                        line-height: 1.75;
                        &::before{
                            display: block;
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 0;
                            width: 10px;
                            height: 10px;
                            border-radius: 100px;
                            background-color: #707070;
                            transform: translateY(-50%);
                        }
                    }
                }
                p{
                    line-height: 1.75;
                }
            }
            &__map{
                iframe{
                    width: 100%;
                    height: 700px;
                    border-radius: 10px;
                }
            }
        }
    }
}



@media only screen and (max-width: 991px) {

    .page{
        &__scene{
            @include spacing_vw(padding, 0 30, 640);
            background-size: 140% auto;
            background-position: center top;
            &__inner{
                @include spacing_vw(margin, 50 0 0, 640);
                @include spacing_vw(padding, 30 20, 640);
                &__title{
                    @include spacing_vw(margin, 0 0 40, 640);
                    &__en{
                        @include fz_vw(80, 640);
                    }
                    &__jp{
                        @include fz_vw(60, 640);
                        span{
                            @include fz_vw(18, 640);
                        }
                    }
                }
                &__catch{
                    @include spacing_vw(padding, 10 15, 640);
                    @include fz_vw(30, 640);
                }
                &__catch2{
                    @include spacing_vw(margin, 0 0 20, 640);
                    @include fz_vw(24, 640);
                    text-align: left;
                }
                &__note{
                    @include fz_vw(16, 640);
                }
                &__subTitle{
                    @include spacing_vw(margin, 0 0 10, 640);
                    @include fz_vw(30, 640);
                }
                &__text{
                    @include spacing_vw(margin, 0 0 80, 640);
                    @include fz_vw(18, 640);
                }
                &__nav{
                    @include spacing_vw(margin, 0 0 30, 640);
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    border-left: 1px solid #707070;
                    li{
                        width: calc(100% / 3);
                        @include fz_vw(14, 640);
                        &:first-child{
                            border-left: none;
                        }
                        a{
                            @include spacing_vw(padding, 5 0, 640);
                        }
                    }
                }
                &__list{
                    justify-content: space-between;
                    @include spacing_vw(gap, 30 0, 640);
                    &__item{
                        width: 48%;
                        padding-bottom: 0;
                        &__title{
                            @include fz_vw(22, 640);
                        }
                        &__subTitle{
                            @include fz_vw(22, 640);
                        }
                        &__text{
                            @include fz_vw(18, 640);
                        }
                        &__link{
                            position: static;
                            width: 22vw;
                            @include fz_vw(18, 640);
                            a{
                                @include spacing_vw(padding, 5, 640); 
                                &::after{
                                    right: 2vw;
                                    @include img_vw(8, 8, 640);
                                }
                                &:hover{
                                    &::after{
                                        right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                &__detail{
                    @include spacing_vw(margin, 0 auto 20, 640);
                    &__image{
                        @include spacing_vw(margin, 0 auto 20, 640);
                    }
                    &__title{
                        @include spacing_vw(margin, 0 auto 20, 640);
                        @include fz_vw(30, 640);
                    }
                    &__text{
                        @include spacing_vw(margin, 0 auto 20, 640);
                        @include fz_vw(16, 640);
                    }
                }
                &__thumb{
                    @include spacing_vw(margin, 0 auto, 640);
                    gap: 0 5%;
                    &__item{
                        width: 30%;
                        &.current{
                            .page__scene__inner__thumb__item__image{
                                &::before{
                                    opacity: 1;
                                }
                                img{
                                    transform: scale(1.05);
                                }
                            }
                        }
                        &__title{
                            @include fz_vw(18, 640);
                        }
                    }
                }
                &__notice{
                    max-width: 950px;
                    @include spacing_vw(margin, 0 auto 60, 640);
                    &__title{
                        @include spacing_vw(margin, 0 0 20, 640);
                        @include fz_vw(20, 640);
                    }
                    ul{
                        @include spacing_vw(margin, 0 0 20, 640);
                        li{
                            @include fz_vw(18, 640);
                            &::before{
                                @include img_vw(10, 10, 640);
                            }
                        }
                    }
                    p{
                        @include fz_vw(16, 640);
                    }
                }
                &__map{
                    iframe{
                        @include img_vw(100%, 620, 640);
                    }
                }
            }
        }
    }
}