@charset "utf-8";
@use '../common' as *;
@use '../mixins' as *;

.page{
    &__top{
        min-height: 2000px;
        &__keyvisual{
            overflow: hidden;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            @include img_vw(100%, 972, 2000);
            &__item{
                position: relative;
                z-index: 10;
                @include spacing_vw(margin, 0 0 0 200, 2000); 
                &__subTitle{
                    margin: 0;
                    @include fz_vw(46, 2000);
                    font-family: $fontEN;
                    font-weight: 600;
                    color: #fff;
                    text-shadow: 0px 0px 20px rgba(0, 0, 0, 1) , 1px 1px 0 rgba(0, 0, 0, .5) , -1px 1px 0 rgba(0, 0, 0, .5) , -1px -1px 0 rgba(0, 0, 0, .5) , 1px -1px 0 rgba(0, 0, 0, .5);
                }
                &__title{
                    @include fz_vw(70, 2000);
                    font-weight: 700;
                    color: #fff;
                    text-shadow: 0px 0px 20px rgba(0, 0, 0, 1) , 1px 1px 0 rgba(0, 0, 0, .5) , -1px 1px 0 rgba(0, 0, 0, .5) , -1px -1px 0 rgba(0, 0, 0, .5) , 1px -1px 0 rgba(0, 0, 0, .5);
                }
                &__en{
                    @include fz_vw(20, 2000);
                    font-weight: 500;
                    color: #fff;
                    text-shadow: 0px 0px 20px rgba(0, 0, 0, 1) , 1px 1px 0 rgba(0, 0, 0, .5) , -1px 1px 0 rgba(0, 0, 0, .5) , -1px -1px 0 rgba(0, 0, 0, .5) , 1px -1px 0 rgba(0, 0, 0, .5);
                }
            }
            &__nav{
                display: flex;
                gap: 30px;
                position: absolute;
                z-index: 1;
                bottom: 30px;
                left: 50%;
                transform: translateX(-50%);
                &__item{
                    cursor: pointer;
                    width: 14px;
                    height: 14px;
                    border: 1px solid #fff;
                    border-radius: 100px;
                    background-color: rgba($color: #fff, $alpha: .2);
                    &:hover,
                    &.current{
                        background-color: #fff;
                    }
                }
            }
            &__bg{
                &.bg1{
                    .page__top__keyvisual__bg1{
                        opacity: 1;
                        transform: scale(1);
                    }
                }
                &.bg2{
                    .page__top__keyvisual__bg2{
                        opacity: 1;
                        transform: scale(1);
                    }
                }
                &.bg3{
                    .page__top__keyvisual__bg3{
                        opacity: 1;
                        transform: scale(1);
                    }
                }
                &.bg4{
                    .page__top__keyvisual__bg4{
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
            &__bg1, &__bg2, &__bg3, &__bg4 {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                transform: scale(1.1);
                @include transition-effect(2.5s,all,cubic-bezier(0.4, 0, 0.2, 1));
            }
            &__bg1 {
                background-image: url(/assets/img/top/keyvisual.jpg);
            }

            &__bg2 {
                background-image: url(/assets/img/top/keyvisual2.jpg);
            }

            &__bg3 {
                background-image: url(/assets/img/top/keyvisual3.jpg);
            }

            &__bg4 {
                background-image: url(/assets/img/top/keyvisual4.jpg);
            }
        }
        &__about{
            max-width: 2000px;
            margin: 0 auto;
            padding: 90px 50px;
            &__catch{
                margin-bottom: 70px;
                font-size: 30px;
                font-weight: 500;
            }
            &__subTitle{
                margin-bottom: 35px;
                font-size: 30px;
                font-weight: 700;
            }
            &__list{
                &__item{
                    display: flex;
                    position: relative;
                    align-items: center;
                    margin-bottom: 60px;
                    &__image{
                        position: absolute;
                        right: 20px;
                        width: 520px;
                        &.even{
                            left: 20px;
                            right: auto;
                        }
                    }
                    &__detail{
                        width: 100%;
                        min-height: 310px;
                        padding: 30px 560px 30px 30px;
                        border-radius: 10px;
                        box-shadow: 0px 0px 15px -5px rgba($color: #000000, $alpha: .3);
                        background-color: #fff;
                        &__title{
                            margin-bottom: 20px;
                            font-size: 30px;
                            font-weight: 500;
                            span{
                                display: inline-block;
                                width: 50px;
                                height: 50px;
                                margin-right: 15px;
                                line-height: 50px;
                                text-align: center;
                                background-color: #808080;
                                font-family: $fontEN;
                                color: #fff;
                            }
                        }
                        &__text{
                            margin: 0;
                            font-size: 26px;
                            line-height: 1.75;
                        }
                        &.even{
                            padding: 30px 30px 30px 560px;
                        }
                    }
                }
            }
        }
        &__scene{
            max-width: 2000px;
            margin: 0 auto;
            padding: 90px 0;
            .title{
                padding: 0 50px;
            }
            &__list{
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                padding: 0 50px 0 30%;
                background-image: url(/assets/img/top/scene-bg.png);
                background-size: auto 100%;
                background-repeat: no-repeat;
                background-position: right 72vw top;
                &.bg1{
                    background-image: url(/assets/img/top/scene-bg.png);
                }
                &.bg2{
                    background-image: url(/assets/img/top/scene-bg2.png);
                }
                &.bg3{
                    background-image: url(/assets/img/top/scene-bg3.png);
                }
                &.bg4{
                    background-image: url(/assets/img/top/scene-bg4.png);
                }
                &.bg5{
                    background-image: url(/assets/img/top/scene-bg5.png);
                }
                &.bg6{
                    background-image: url(/assets/img/top/scene-bg6.png);
                }
                @media only screen and (min-width: 2000px) {
                    padding: 0 50px 0 28.8%;
                    background-image: url(/assets/img/top/scene-2000-bg.png);
                    background-position: left top;
                    background-size: auto 100%;
                    &.bg1{
                        background-image: url(/assets/img/top/scene-2000-bg.png);
                    }
                    &.bg2{
                        background-image: url(/assets/img/top/scene-2000-bg2.png);
                    }
                    &.bg3{
                        background-image: url(/assets/img/top/scene-2000-bg3.png);
                    }
                    &.bg4{
                        background-image: url(/assets/img/top/scene-2000-bg4.png);
                    }
                    &.bg5{
                        background-image: url(/assets/img/top/scene-2000-bg5.png);
                    }
                    &.bg6{
                        background-image: url(/assets/img/top/scene-2000-bg6.png);
                    }
                }
                &__item{
                    position: relative;
                    overflow: hidden;
                    width: calc((100% - 80px) / 3);
                    border-radius: 10px;
                    background-color: #fff;
                    >a{
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                    &__image{
                        img{
                            @include transition-effect();
                        }
                        a{
                            display: block;
                            position: relative;
                            overflow: hidden;
                            &::before{
                                content: '';
                                display: block;
                                position: absolute;
                                opacity: 0;
                                z-index: 1;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: rgba($color: #0C0C34, $alpha: .5);
                                @include transition-effect();
                            }
                            /*
                            &::after{
                                content: '';
                                display: block;
                                position: absolute;
                                z-index: 1;
                                opacity: 0;
                                top: 50%;
                                left: 50%;
                                @include img_vw(79, 79, 2000);
                                background-repeat: no-repeat;
                                background-image: url(/assets/img/icon/arrow.svg);
                                background-size: 100% auto;
                                transform: translateX(-50%) translateY(-50%);
                                @include transition-effect();
                            }*/
                            &:hover{
                                &::before{
                                    opacity: 1;
                                }
                                &::after{
                                    opacity: 1;
                                }
                                img{
                                    transform: scale(1.05);
                                }
                            }
                        }
                    }
                    &__detail{
                        padding: 20px 20px 80px;
                        &__title{
                            margin-bottom: 20px;
                            font-size: 30px;
                            font-weight: 500;
                        }
                        &__text{
                            font-size: 18px;
                            line-height: 2;
                        }
                        &__link{
                            position: absolute;
                            z-index: 1;
                            left: 20px;
                            bottom: 20px;
                            width: 180px;
                            margin: 0;
                            font-size: 18px;
                            font-weight: 700;
                            a{
                                display: block;
                                position: relative;
                                padding: 5px 0;
                                border-bottom: 1px dotted #707070;
                                text-decoration: none;
                                color: #222;
                                &::after{
                                    content: '';
                                    width: 8px;
                                    height: 8px;
                                    border: 0;
                                    border-top: solid 1px #333;
                                    border-right: solid 1px #333;
                                    transform: rotate(45deg);
                                    position: absolute;
                                    top: 0;
                                    right: 5px;
                                    bottom: 0;
                                    margin: auto;
                                    @include transition-effect();
                                }
                                &:hover{
                                    &::after{
                                        right: 0;
                                    }
                                }
                            }
                        }
                    }
                    &:hover{
                        .page__top__scene__list__item__image{
                            a{
                                &::before{
                                    opacity: 1;
                                }
                                &::after{
                                    opacity: 1;
                                }
                                img{
                                    transform: scale(1.05);
                                }
                            }
                        }
                        .page__top__scene__list__item__detail__link{
                            a{
                                &::after{
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        &__work{
            padding: 90px 0;
            background-color: #fff;
            .title{
                max-width: 2000px;
                margin: 0 auto 40px;
                padding: 0 50px;
            }
            &__title{
                max-width: 2000px;
                margin: 0 auto 20px;
                padding: 0 50px;
                font-size: 28px;
                font-weight: 700;
            }
            &__list{
                margin-bottom: 40px;
                .slick-prev{
                    position: absolute;
                    z-index: 1;
                    appearance: none;
                    top: 50%;
                    left: 30px;
                    width: 44px;
                    height: 88px;
                    margin: 0;
                    padding: 0;
                    background: none;
                    background-image: url(/assets/img/icon/prev.svg);
                    background-repeat: no-repeat;
                    border: none;
                    font-size: 0;
                    transform: translateY(-50%);
                }
                .slick-next{
                    position: absolute;
                    z-index: 1;
                    appearance: none;
                    top: 50%;
                    right: 30px;
                    width: 44px;
                    height: 88px;
                    margin: 0;
                    padding: 0;
                    background: none;
                    background-image: url(/assets/img/icon/prev.svg);
                    background-repeat: no-repeat;
                    border: none;
                    font-size: 0;
                    transform: translateY(-50%) scale(-1, 1) ;
                }
                &__item{
                    position: relative;
                    overflow: hidden;
                    width: 300px;
                    min-height: 380px;
                    margin: 0 10px 20px;
                    border-radius: 10px;
                    background-color: #fff;
                    box-shadow: 0px 0px 15px -5px rgba($color: #000000, $alpha: .3);
                    &__image{
                        position: relative;
                        aspect-ratio: 88 / 56;
                        &__annotation{
                            position: absolute;
                            z-index: 1;
                            font-size: 14px;
                            right: 10px;
                            bottom: 10px;
                            margin: 0;
                            color: #fff;
                        }
                        img{
                            width: 100%;
                            height: auto;
                            @include transition-effect();
                            object-fit: cover;
                            height: 100%;
                        }
                        a{
                            display: block;
                            position: relative;
                            overflow: hidden;
                            aspect-ratio: 88 / 56;
                            &::before{
                                content: '';
                                display: block;
                                position: absolute;
                                opacity: 0;
                                z-index: 1;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: rgba($color: #0C0C34, $alpha: .5);
                                @include transition-effect();
                            }
                            /*
                            &::after{
                                content: '';
                                display: block;
                                position: absolute;
                                z-index: 1;
                                opacity: 0;
                                top: 50%;
                                left: 50%;
                                width: 50px;
                                height: 50px;
                                background-repeat: no-repeat;
                                background-image: url(/assets/img/icon/arrow.svg);
                                background-size: 100% auto;
                                transform: translateX(-50%) translateY(-50%);
                                @include transition-effect();
                            }
                            */
                            &:hover{
                                &::before{
                                    opacity: 1;
                                }
                                &::after{
                                    opacity: 1;
                                }
                                img{
                                    transform: scale(1.05);
                                }
                            }
                        }
                    }
                    &__detail{
                        padding: 10px 20px 20px;
                        &__text{
                            font-size: 17px;
                            color: #808080;
                        }
                        &__tag{
                            margin-bottom: 5px;
                            a{
                                display: inline-block;
                                padding: 2px 10px;
                                background-color: #fff;
                                border: 1px solid #fff;
                                font-size: 16px;
                                font-weight: 600;
                                color: #fff;
                                text-decoration: none;
                            }
                            &.cf{
                                a{
                                    background-color: #219053;
                                }
                            }
                            &.mv{
                                a{
                                    background-color: #303065;
                                }
                            }
                            &.pv{
                                a{
                                    background-color: #ff8b8b;
                                }
                            }
                            &.vtr{
                                a{
                                    background-color: #632980;
                                }
                            }
                            &.web{
                                a{
                                    background-color: #f2994a;
                                }
                            }
                            &.web-cm{
                                a{
                                    background-color: #f2994a;
                                }
                            }
                            &.web-movie{
                                a{
                                    background-color: #f2994a;
                                }
                            }
                            &.uncategorized,
                            &.other{
                                a{
                                    background-color: #fff;
                                    border: 1px solid #ccc;
                                    color: #333;
                                }
                            }
                            &.still-picture{
                                a{
                                    background-color: #f2994a;
                                }
                            }
                            &.drama{
                                a{
                                    background-color: #2d9cdb;
                                }
                            }
                            &.variety{
                                a{
                                    background-color: #C3B83B;
                                }
                            }
                            &.information{
                                a{
                                    background-color: #bdbdbd;
                                }
                            }
                            &.information-program{
                                a{
                                    background-color: #bdbdbd;
                                }
                            }
                            &.movie{
                                a{
                                    background-color: #eb5757;
                                }
                            }
                            &.magazine{
                                a{
                                    background-color: #f2994a;
                                }
                            }
                        }
                        &__title{
                            margin-bottom: 0;
                            font-size: 18px;
                            a{
                                color: #222;
                                text-decoration: none;
                                &:hover{
                                    text-decoration: underline;
                                }
                            }
                        }
                        &__link{
                            width: 180px;
                            font-size: 18px;
                            font-weight: 700;
                            a{
                                display: block;
                                position: relative;
                                padding: 5px 0;
                                border-bottom: 1px dotted #707070;
                                text-decoration: none;
                                color: #222;
                                &::after{
                                    content: '';
                                    width: 8px;
                                    height: 8px;
                                    border: 0;
                                    border-top: solid 1px #333;
                                    border-right: solid 1px #333;
                                    transform: rotate(45deg);
                                    position: absolute;
                                    top: 0;
                                    right: 5px;
                                    bottom: 0;
                                    margin: auto;
                                    @include transition-effect();
                                }
                                &:hover{
                                    &::after{
                                        right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &__contact{
            max-width: 2000px;
            margin: 0 auto;
            padding: 90px 50px 120px;
            &__button{
                position: relative;
                max-width: 1000px;
                height: 170px;
                margin: 0 auto;
                background-color: #F19B4B;
                background-image: url(/assets/img/icon/arrow.svg);
                background-size: 50px auto;
                background-position: right 48px center;
                background-repeat: no-repeat;
                @include transition-effect();
                &::before{
                    display: block;
                    position: absolute;
                    z-index: 1;
                    opacity: 0.3;
                    content: '';
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 170px;
                    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 100%);
                    mix-blend-mode: overlay;
                }
                a{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    z-index: 2;
                    height: 170px;
                    font-size: 44px;
                    font-weight: 700;
                    color: #fff;
                    text-decoration: none;
                    text-shadow: 0px 0px 20px #ED7D35 , 1px 1px 0 #ED7D35 , -1px 1px 0 #ED7D35 , -1px -1px 0 #ED7D35 , 1px -1px 0 #ED7D35;
                    span{
                        display: block;
                        font-size: 26px;
                        font-weight: 600;
                    }
                }
                &:hover{
                    opacity: 0.7;
                }
            }
        }
    }
}



@media only screen and (max-width: 991px) {

    .page{
        &__top{
            &__keyvisual{
                @include img_vw(100%, 950, 640);
                &__item{
                    @include spacing_vw(margin, 0 0 0 30, 640); 
                    &__subTitle{
                        @include fz_vw(36, 640);
                    }
                    &__title{
                        @include fz_vw(56, 640);
                    }
                    &__en{
                        @include fz_vw(22, 640);
                    }
                    &__bg1, &__bg2, &__bg3, &__bg4 {
                        @include img_vw(100%, 950, 640);
                    }
                }
                &__bg1 {
                    background-image: url(/assets/img/top/keyvisual-sp.jpg);
                }
                &__bg2 {
                    background-image: url(/assets/img/top/keyvisual2-sp.jpg);
                }
                &__bg3 {
                    background-image: url(/assets/img/top/keyvisual3-sp.jpg);
                }
                &__bg4 {
                    background-image: url(/assets/img/top/keyvisual4-sp.jpg);
                }
                &__nav{
                    bottom: 10vw;
                    @include spacing_vw(gap, 0 34, 640);
                    &__item{
                        @include img_vw(18, 18, 640);
                    }
                }
            }
            &__about{
                @include spacing_vw(padding, 60 30, 640); 
                &__catch{
                    @include spacing_vw(margin, 0 0 40 0, 640); 
                    @include fz_vw(28, 640);
                }
                &__subTitle{
                    @include spacing_vw(margin, 0 0 60 0, 640); 
                    @include fz_vw(32, 640);
                }
                &__list{
                    &__item{
                        display: block;
                        position: relative;
                        @include spacing_vw(margin, 20 0 50, 640); 
                        &__detail{
                            width: auto;
                            @include spacing_vw(padding, 330 20 20, 640); 
                            box-shadow: 0px 0px 15px -5px rgba($color: #000000, $alpha: .3);
                            &__title{
                                position: relative;
                                @include spacing_vw(margin, 0 0 20, 640); 
                                @include spacing_vw(padding, 8 0 0 65, 640); 
                                @include fz_vw(30, 640);
                                span{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    @include img_vw(50, 50, 640);
                                    margin: 0;
                                    @include line_height_vw(50, 640);
                                }
                            }
                            &__text{
                                @include fz_vw(26, 640);
                            }
                            &.even{
                                @include spacing_vw(padding, 330 20 20, 640); 
                                border-radius: 10px;
                            }
                        }
                        &__image{
                            position: absolute;
                            top: -3vw;
                            right: auto;
                            left: 50%;
                            @include img_vw(500, auto, 640);
                            transform: translateX(-50%);
                            img{
                                width: 100%;
                            }
                            &.even{
                                left: 50%;
                            }
                        }
                    }
                }
            }
            &__scene{
                @include spacing_vw(padding, 60 30, 640); 
                .title{
                    padding: 0;
                }
                &__list{
                    @include spacing_vw(gap, 20, 640); 
                    padding: 0;
                    background: none;
                    &.bg1,
                    &.bg2,
                    &.bg3,
                    &.bg4,
                    &.bg5,
                    &.bg6{
                        background: none;
                    }
                    &__item{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        width: 100%;
                        &__image{
                            width: 50%;
                            a{
                                &::after{
                                    @include img_vw(50, 50, 640);
                                }
                            }
                        }
                        &__title{
                            width: 50%;
                            margin: 0;
                            @include spacing_vw(padding, 20, 640); 
                            @include fz_vw(30, 640);
                        }
                        &__detail{
                            padding: 20px;
                            &__title{
                                margin-bottom: 0;
                                @include fz_vw(30, 640);
                            }
                            &__text{
                                @include fz_vw(20, 640);
                            }
                            &__link{
                                position: static;
                                width: 32vw;
                                margin: 0 auto;
                                @include fz_vw(20, 640);
                                a{
                                    @include spacing_vw(padding, 5, 640); 
                                    &::after{
                                        right: 2vw;
                                        @include img_vw(8, 8, 640);
                                    }
                                    &:hover{
                                        &::after{
                                            right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &__work{
                @include spacing_vw(padding, 60 0, 640); 
                .title{
                    @include spacing_vw(padding, 0 30, 640); 
                }
                &__title{
                    @include spacing_vw(padding, 0 30, 640); 
                }
                &__list{
                    .slick-prev{
                        @include img_vw(44, 88, 640);
                        background-size: 100% auto;
                    }
                    .slick-next{
                        @include img_vw(44, 88, 640);
                        background-size: 100% auto;
                    }
                    &__item{
                        width: 60vw;
                        min-height: 80vw;
                        &__image{
                            &__annotation{
                                @include fz_vw(16, 640);
                            }
                            a{
                                &::after{
                                    @include img_vw(50, 50, 640);
                                }
                            }
                        }
                        &__detail{
                            @include spacing_vw(padding, 15 20 50, 640); 
                            &__text{
                            @include spacing_vw(margin, 0 0 10, 640); 
                                @include fz_vw(20, 640);
                            }
                            &__tag{
                                a{
                                    margin: 0;
                                    @include spacing_vw(padding, 5 10, 640); 
                                    @include fz_vw(16, 640);
                                }
                            }
                            &__title{
                                @include fz_vw(20, 640);
                            }
                        }
                    }
                }
            }
            &__contact{
                @include spacing_vw(padding, 60 30 110, 640);
                &__button{
                    @include img_vw(100%, 170, 640);
                    background-size: 8vw auto;
                    background-position: right 2vw center;
                    text-align: center;
                    a{
                        @include img_vw(100%, 170, 640);
                        @include fz_vw(36, 640);
                        line-height: 1.1;
                        span{        
                            @include fz_vw(24, 640);
                            line-height: 2;
                        }
                    }
                    &::before{
                        @include img_vw(100%, 170, 640);
                    }
                }
            }
        }
    }

}