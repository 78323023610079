@charset "utf-8";
@use 'common' as *;
@use 'mixins' as *;

.title{
    position: relative;
    margin-bottom: 40px;
    &__en{
        display: inline-block;
        font-size: 100px;
        font-weight: 600;
        font-family: $fontB;
        line-height: 1;
        background: linear-gradient(90deg, rgba(235,87,85,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
        background-clip: text;
         -webkit-text-fill-color: transparent;
    }
    &__jp{
        position: absolute;
        bottom: 0;
        font-size: 50px;
        font-weight: 700;
        line-height: 1;
    }
    &.color2 &__en {
        background: linear-gradient(90deg, rgba(242,153,83,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
        background-clip: text;
         -webkit-text-fill-color: transparent;
    }
    &.color3 &__en {
        background: linear-gradient(90deg, rgba(185,107,220,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
        background-clip: text;
         -webkit-text-fill-color: transparent;
    }
    &.color4 &__en {
        background: linear-gradient(90deg, rgba(190,189,189,0.2) 0%, rgba(115,172,204,0.2) 61%, rgba(44,156,218,0.2) 100%);
        background-clip: text;
         -webkit-text-fill-color: transparent;
    }
}

@media only screen and (max-width: 991px) {

    .title{
        @include spacing_vw(margin, 0 0 40, 640);
        &__en{
            @include fz_vw(90, 640);
        }
        &__jp{
            @include fz_vw(40, 640);
        }
    }

}

.button{
    position: relative;
    width: 480px;
    height: 80px;
    margin: 0 auto;
    background-color: #707070;
    clip-path: polygon(0% 0%, 94% 0, 100% 50%, 94% 100%, 0% 100%);
    font-size: 20px;
    line-height: 80px;
    font-weight: 700;
    text-align: center;
    text-indent: -1em;
    a{
        display: block;
        position: relative;
        text-decoration: none;
        color: #222;
        @include transition-effect();
        &[target='_blank']{
            &::after{
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                right: 24px;
                background-image: url(/assets/img/icon/blank.svg);
                width: 18px;
                height: 18px;
                transform: translateY(-50%);
            }
        }
        &:not([target='_blank']) {
            &::after {
                content: '';
                width: 10px;
                height: 10px;
                border: 0;
                border-top: solid 1px #222;
                border-right: solid 1px #222;
                transform: rotate(45deg);
                position: absolute;
                top: 0;
                right: 24px;
                bottom: 0;
                margin: auto;
                @include transition-effect();
            }
        }
        &:hover{
            background-color: #707070;
            color: #fff;
        }
    }
    &::before{
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        width: 478px;
        height: 78px;
        clip-path: polygon(0% 0%, 94% 0, 100% 50%, 94% 100%, 0% 100%);
        background-color: #fff;
    }
}

@media only screen and (max-width: 991px) {

    .button{
        @include img_vw(520, 90, 640);
        @include fz_vw(22, 640);
        @include line_height_vw(90, 640);
        a{
            &[target='_blank']{
                &::after{
                    right: 4vw;
                    background-size: 100% auto;
                    @include img_vw(18, 18, 640);
                }
            }
            &:not([target='_blank']) {
                &::after {
                    right: 4vw;
                    @include img_vw(12, 12, 640);
                }
            }
        }
        &::before{
            @include img_vw(517, 87, 640);
        }
    }

}

.topicPath{
    max-width: 1900px;
    margin: 0 auto;
    padding: 50px 0;
    &__list{
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 0;
        padding: 0;
        list-style: none;
        &__item{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 1rem;
            font-size: 18px;
            color: #A0A0A0;
            &:not(:last-of-type)::after {
                content: "";
                display: block;
                content: "";
                width: 8px;
                height: 8px;
                margin-right: 0.5rem;
                border-top: 1px solid #222;
                border-right: 1px solid #222;
                transform: rotate(45deg);
            }
            a{
                color: #000;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {

    .topicPath{
        @include spacing_vw(padding, 30 0, 640);
        &__list{
            &__item{
                @include fz_vw(18, 640);
                &:not(:last-of-type)::after {
                    @include img_vw(8, 8, 640);
                }
            }
        }
    }
}

.pageTop{
    position: fixed;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    right: 20px;
    bottom: 20px;
    transform: translateY(50px);
    @include transition-effect();
    a{
        display: block;
        width: 60px;
        height: 60px;
        background-image: url(/assets/img/icon/pagetop.png);
        background-size: 60px;
        @include transition-effect();
        &:hover{
            opacity: 0.7;
        }
    }
    &.show{
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 991px) {
    .pageTop{
        bottom: 16vw;
        right: 1.8vw;
        a{
            @include img_vw(80, 80, 640);
            background-size: 100% auto;
        }
    }
}

.pagination{
	display: block;
	ul{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 20px;
		list-style: none;
		margin: 0 0 0;
		padding: 50px 0;
		li{
			font-size: 22px;
            font-family: $fontB;
			font-weight: 500;
			text-align: center;
			a{
				display: block;
				position: relative;
				width: 40px;
				height: 40px;
				line-height: 40px;
				border-radius: 100px;
				text-decoration: none;
				color: #222;
				transition: .3s all cubic-bezier(0.4, 0, 0.2, 1);
				&:hover{
					background-color: #1F60A7;
					color: #fff;
				}
				&.next{
                    font-size: 0;
					&::before{
						display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
						content: "";
						width: 0;
						height: 0;
						border-top: 8px solid #1F60A7;
						border-right: 8px solid #1F60A7;
                        border-left: 8px solid transparent;
                        border-bottom: 8px solid transparent;
						transform: translateY(-50%) translateX(-50%) rotate(45deg);
						transition: .3s all cubic-bezier(0.4, 0, 0.2, 1);
					}
					&:hover{
                        background: none;
                        &::before{
						    transform: translateY(-50%) translateX(-30%) rotate(45deg);
                        }
					}
				}
				&.prev{
                    font-size: 0;
					&::before{
						display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
						content: "";
						width: 0;
						height: 0;
						border-top: 8px solid #1F60A7;
						border-right: 8px solid #1F60A7;
                        border-left: 8px solid transparent;
                        border-bottom: 8px solid transparent;
						transform: translateY(-50%) translateX(-50%) rotate(-135deg);
						transition: .3s all cubic-bezier(0.4, 0, 0.2, 1);
					}
					&:hover{
                        background: none;
                        &::before{
						    transform: translateY(-50%) translateX(-70%) rotate(-135deg);
                        }
					}
				}
			}
			span{
				display: block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				border-radius: 100px;
				background-color: #fff;
				text-decoration: none;
				color: #000;
                &.current{
					background-color: #1F60A7;
                    color: #fff;
                }
			}
		}
	}
}

@media only screen and (max-width: 991px) {
   .pagination{
        ul{
            gap: 1vw;
            list-style: none;
            margin: 0 0 0;
            padding: 6vw 0;
            li{
                @include fz_vw(18, 640);
                a{
                    width: 8vw;
                    height: 8vw;
                    line-height: 8vw;
                    &.next{
                        &::before{
                            border-top: 1vw solid #1F60A7;
                            border-right: 1vw solid #1F60A7;
                            border-left: 1vw solid transparent;
                            border-bottom: 1vw solid transparent;
                        }
                    }
                    &.prev{
                        &::before{
                            border-top: 1vw solid #1F60A7;
                            border-right: 1vw solid #1F60A7;
                            border-left: 1vw solid transparent;
                            border-bottom: 1vw solid transparent;
                        }
                    }
                }
                span{
                    width: 8vw;
                    height: 8vw;
                    line-height: 8vw;
                }
                &.current{
                    span{
                        width: 8vw;
                        height: 8vw;
                        line-height: 8vw;
                    }
                }
            }
        }
    }
}
.screen-reader-text{
    display: none;
}