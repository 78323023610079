@charset "utf-8";
@use "sass:math";

@function get_vw($size, $viewport: 2000) {
  $rate: math.div(100, $viewport);
  @return calc(#{$rate} * #{$size} * 1vw);
}

@mixin fz_vw($font_size: 20, $viewport: 2000) {
  font-size: #{$font_size}px; // Fallback (px)
  font-size: get_vw($font_size, $viewport); // vw 計算
}



@mixin img_vw($width, $height: auto, $viewport: 2000, $keep_aspect: false) {
  @if $width == auto or $width == 100% {
    width: #{$width};
  } @else {
    width: #{$width}px; // フォールバック
    width: get_vw($width, $viewport); // vw 計算
  }

  @if $height == auto or $height == 100% {
    height: #{$height};
  } @else {
    height: #{$height}px; // フォールバック
    height: get_vw($height, $viewport); // vw 計算
  }

  @if $keep_aspect and type-of($width) == number and type-of($height) == number {
    aspect-ratio: math.div($width, $height);
  }
}

// line-height を高さに合わせて設定
@mixin line_height_vw($height, $viewport: 2000) {
  line-height: #{$height}px; // フォールバック
  line-height: get_vw($height, $viewport); // vw 計算
}

// ミックスイン（1～4つの値を受け取れる）
@function get_vw($size, $viewport: 2000) {
  @if $size == auto {
    @return auto;
  }
  $rate: math.div(100, $viewport);
  @return calc(#{$rate} * #{$size} * 1vw);
}

@mixin spacing_vw($property, $values, $viewport: 2000) {
  $length: length($values);
  $fallback: ();
  $vw-values: ();

  @for $i from 1 through $length {
    $val: nth($values, $i);
    $fallback: append($fallback, if($val == auto, auto, #{$val}px));
    $vw-values: append($vw-values, get_vw($val, $viewport));
  }

  #{$property}: $fallback;
  #{$property}: $vw-values;
}

// トランジション効果ミックスイン
@mixin transition-effect(
  $property: all, 
  $duration: 0.15s, 
  $easing: cubic-bezier(0.4, 0, 0.2, 1)
) {
  transition: #{$duration} #{$property} #{$easing};
}