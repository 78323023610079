@charset "utf-8";
@use 'common' as *;
@use 'mixins' as *;

img{
    max-width: 100%;
}

body{
    background-color: rgba($color: #F2F2F2, $alpha: .5);
    background-image: url(/assets/img/bg.png);
    background-position: center bottom;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: $fontMain;
    font-weight: 400;
    color: #222;
    &.noScroll{
        overflow: hidden;
    }
}

.menu{
    display: none;
    position: fixed;
    z-index: 101;
    cursor: pointer;
    top: 1.8vw;
    right: 1.8vw;
    width: 8vw;
    height: 8vw;
    background-image: url(/assets/img/menu.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

@media only screen and (max-width: 991px) {
    .menu{
        display: block;
    }
}

.navigation{
    display: block;
    visibility: hidden;
    opacity: 0;
    overflow-y: scroll;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    @include spacing_vw(padding, 50, 640); 
    background-color: #fff;
    @include transition-effect();
    &__close{
        display: block;
        position: absolute;
        cursor: pointer;
        top: 1.8vw;
        right: 1.8vw;
        width: 8vw;
        height: 8vw;
        background-image: url(/assets/img/close.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
    &__logo{
        @include spacing_vw(margin, 0 0 28, 640); 
        @include fz_vw(15, 640);
        text-align: center;
        span{
            display: block;
        }
        a{
            text-decoration: none;
            color: #222;
        }
        img{
            @include img_vw(293, auto, 640);
        }
    }
    &__list{
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
        &__item{
            border-bottom: 1px solid #E3E3E3;
            @include fz_vw(22, 640);
            font-weight: 500;
            a{
                display: block;
                @include spacing_vw(padding, 28 0, 640); 
                color: #222;
                text-decoration: none;
            }
            &:first-child{
                border-top: 1px solid #E3E3E3;
            }
        }
    }
    &.active{
        visibility: visible;
        opacity: 1;
    }
}

.header{
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 100;
    top: 0;
    padding: 0 0 0 10px;
    background-color: #fff;
    font-family: $fontIBM;
    font-weight: 500;
    &__logo{
        font-family: $fontMain;
        font-size: 14px;
        line-height: 1;
        img{
            vertical-align: middle;
            transform: translateY(-3px);
        }
        span{
            margin-left: 10px;
            vertical-align: middle;
        }
        a{
            text-decoration: none;
            color: #222;
        }
    }
    &__nav{
        display: flex;
        align-items: center;
        &__list{
            display: flex;
            gap: 50px;
            margin: 0 60px 0 0;
            list-style: none;
            &__item{
                font-size: 16px;
                font-weight: 400;
                a{
                    text-decoration: none;
                    color: #222;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
        &__result{
            position: relative;
            width: 120px;
            height: 80px;
            background-color: #2E9DE0;
            line-height: 80px;
            text-align: center;
            text-shadow: 1px 1px 2px #2080D7;
            @include transition-effect();
            a{
                display: block;
                position: relative;
                z-index: 1;
                font-size: 18px;
                font-weight: 500;
                text-decoration: none;
                color: #fff;
            }
            &::before{
                opacity: 0.3;
                display: block;
                content: '';
                mix-blend-mode: overlay;
                position: absolute;
                background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 100%);
                width: 120px;
                height: 80px;
            }
            &:hover{
                opacity: 0.7;
            }
        }
        &__photo{
            position: relative;
            width: 240px;
            height: 80px;
            background-color: #F19B4B;
            line-height: 80px;
            text-align: center;
            text-shadow: 1px 1px 2px #ED7E35;
            @include transition-effect();
            a{
                display: block;
                position: relative;
                z-index: 1;
                font-size: 22px;
                font-weight: 500;
                text-decoration: none;
                color: #fff;
            }
            &::before{
                opacity: 0.3;
                display: block;
                content: '';
                mix-blend-mode: overlay;
                position: absolute;
                background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 100%);
                width: 240px;
                height: 80px;
            }
            &:hover{
                opacity: 0.7;
            }
        }
    }
}


@media only screen and (max-width: 1599px) {
    .header{
        &__nav{
            &__list{
                gap: 0 20px;
                margin-right: 20px;
            }
        }
    }
}


@media only screen and (max-width: 991px) {
    .header{
        position: relative;
        padding: 0;
        &__nav{
            &__list{
                display: none;
            }
            &__result{
                display: none;
                @include img_vw(130, 80, 640);
                @include line_height_vw(80, 640);
                a{
                    @include fz_vw(18, 640);
                }
                &::before{
                    @include img_vw(130, 80, 640);
                }
            }
            &__photo{
                display: none;
                @include img_vw(160, 80, 640);
                line-height: 1.2;
                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include img_vw(160, 80, 640);
                    @include fz_vw(20, 640);
                }
                &::before{
                    @include img_vw(160, 80, 640);
                }
            }
        }
        &__logo{
            position: static;
            @include spacing_vw(margin, 10 0 10 10, 640); 
            @include fz_vw(14, 640);
            text-align: center;
            img{
                @include img_vw(204, auto, 640);
                transform: none;
            }
            span{
                display: block;
            }
        }
    }
}



.footer{
    position: relative;
    padding: 40px 0 10px;
    background-color: #fff;
    &__logo{
        position: absolute;
        top: 20px;
        left: 10px;
        font-size: 14px;
        line-height: 1;
        img{
            vertical-align: middle;
        }
        span{
            margin-left: 10px;
            vertical-align: middle;
        }
        a{
            text-decoration: none;
            color: #222;
        }
    }
    &__nav{
        margin-bottom: 40px;
        &__list{
            display: flex;
            gap: 0 60px;
            justify-content: center;
            list-style: none;
            margin: 0;
            padding: 0;
            line-height: 1;
            &__item{
                font-size: 16px;
                font-weight: 500;
                a{
                    text-decoration: none;
                    color: #181B1E;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &__copy{
        font-size: 14px;
        text-align: center;
    }
}

@media only screen and (max-width: 1299px) {
    .footer{
        padding: 80px 0 10px;
    }
}

@media only screen and (max-width: 991px) {
    .footer{
        @include spacing_vw(padding, 16 30 100, 640); 
        &__logo{
            position: static;
            @include spacing_vw(margin, 0 0 36, 640); 
            @include fz_vw(16, 640);
            img{
                @include img_vw(224, auto, 640);
            }
        }
        &__nav{
            @include spacing_vw(margin, 0 0 36, 640); 
            &__list{
                &__item{
                    @include fz_vw(16, 640);
                }
            }
        }
        &__copy{
            @include fz_vw(16, 640);
        }
    }
}

.fixedNav{
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 201;
    width: 100%;
    padding: 10px;
    &__result{
        position: relative;
        width: 30%;
        height: 80px;
        background-color: #2E9DE0;
        line-height: 80px;
        text-align: center;
        text-shadow: 1px 1px 2px #2080D7;
        @include transition-effect();
        a{
            display: block;
            position: relative;
            z-index: 1;
            font-size: 18px;
            font-weight: 500;
            text-decoration: none;
            color: #fff;
        }
        &::before{
            opacity: 0.3;
            display: block;
            content: '';
            mix-blend-mode: overlay;
            position: absolute;
            background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 100%);
            width: 100%;
            height: 80px;
        }
        &:hover{
            opacity: 0.7;
        }
    }
    &__photo{
        position: relative;
        width: 70%;
        height: 80px;
        background-color: #F19B4B;
        line-height: 80px;
        text-align: center;
        text-shadow: 1px 1px 2px #ED7E35;
        @include transition-effect();
        a{
            display: block;
            position: relative;
            z-index: 1;
            font-size: 22px;
            font-weight: 500;
            text-decoration: none;
            color: #fff;
        }
        &::before{
            opacity: 0.3;
            display: block;
            content: '';
            mix-blend-mode: overlay;
            position: absolute;
            background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 100%);
            width: 100%;
            height: 80px;
        }
        &:hover{
            opacity: 0.7;
        }
    }
}
@media only screen and (max-width: 991px) {
    .fixedNav{
        display: flex;
        padding: 1.8vw;
        &__result{
            @include img_vw(30%, 80, 640);
            @include line_height_vw(80, 640);
            a{
                @include fz_vw(18, 640);
            }
            &::before{
                @include img_vw(100%, 80, 640);
            }
        }
        &__photo{
            @include img_vw(70%, 80, 640);
            line-height: 1.2;
            a{
                display: flex;
                justify-content: center;
                align-items: center;
                @include img_vw(100%, 80, 640);
                @include fz_vw(20, 640);
            }
            &::before{
                @include img_vw(100%, 80, 640);
            }
        }
    }
}